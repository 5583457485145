@import "variables";
@import "bootstrap-configurator";
@import "fontawesome-scss/fontawesome";
@import "fontawesome-scss/brands";
@import "fontawesome-scss/solid";
@import "fontawesome-scss/regular";

/*
  Style Format
  layout (l-box), how modules are laid out but not visual appearance
  module (m-profile), reusable visual styles
  element (m-profile__image), parts of a module
  modifier (m-profile--orangebg), modifier for elements
  tile examples could include col-12 col-lg-6 m-tile m-tile--blue m-tile--large to have a large tile with blue bg
  text inside could be l-centered-text m-tile__text m-tile__text--white for centered white text inside tile text element
*/

//breakpoints xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200

/* Fonts */
@font-face {
  font-family: "Source Serif Pro";
  src: url("/fonts/SourceSerifPro-Regular.otf");
  src: url("/fonts/SourceSerifPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("/fonts/SourceSerifPro-Semibold.otf");
  src: url("/fonts/SourceSerifPro-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("/fonts/SourceSerifPro-Bold.otf");
  src: url("/fonts/SourceSerifPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("/fonts/SourceSerifPro-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@mixin standard-button {
  color: $white !important;
  background: $orange;
  border: 2px solid $orange;
  margin: 0 10px;
  padding: 12px 30px;
  font-weight: 700;
  text-decoration: none;
  font-size: 1.25rem;
}

@mixin inverse-button {
  color: $dark-blue;
  background: $white;
  border: 2px solid $dark-blue;
  margin: 0 10px;
  padding: 12px 30px;
  font-weight: 600;
  text-decoration: none;
}

@mixin full-drop-shadow {
  box-shadow: 0 1px 5px rgba(35, 47, 156, .16);
}
/*
  Global HTML/Body/Font
*/

@media only screen and (max-width: 991px) {
  /* .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .row > * {
    padding-right: 25px;
    padding-left: 25px;
  } */

  .wide-row > * {
    padding-right: 14px;
    padding-left: 14px;
  }

  .x-wide-row > * {
    padding-right: 10px;
    padding-left: 10px;
  }

  .m-fullwidth-mobile {
    width: 100%;
  }
}

.hc-skiplink {
  display: none;
}

.visually-hidden {
  color: $white;
  position: fixed;
  height: 1px;
  width: 1px;
  left: -100px;
  top: -100px;
}

.m-standard-button {
  @include standard-button();

  &:hover,
  &:active {
    background: $orangehover;
    border-color: $orangehover;
    text-decoration: none;
  }

  &:focus {
    background: $orangeselected;
    border-color: $midnight;
    color: $white;
    outline: 0;
  }

  &:visited {
    color: $white;
  }
}

@media only screen and (min-width: $phone) {
  .m-standard-button {
    padding: 14px 38px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-standard-button {
    padding: 14px 58px;
  }
}


.m-secondary-button {
  @include standard-button();

  &:hover,
  &:active {
    background: $inversehover;
    border-color: #0081a8;
    text-decoration: none;
  }

  &:focus {
    background: $secondary;
    border-color: $midnight;
    color: $white;
  }

  &:visited {
    color: $white;
  }
}

.m-inverse-button {
  @include inverse-button();

  &:hover,
  &:active {
    background: $inversehover;
    border-color: $midnight;
    color: $midnight;
    text-decoration: none;
  }

  &:focus {
    background: $white;
    color: $midnight;
    border-color: #0081a8;
  }

  &:visited {
    color: $midnight;
  }
}


@media only screen and (min-width: $phone) {
  .m-inverse-button {
    padding: 14px 38px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-inverse-button {
    padding: 14px 58px;
  }
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.justify-content-center {
  justify-content: center;
}

/* Header to bar width */
@media only screen and (max-width: 1600px) {
  .mobile-fullwidth {
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .nocontainerpadding-mobile {
    padding: 0;
    margin: 0;
    min-width: 100%;
  }

  .nopadding-mobile {
    padding: 0 !important;
  }
}


.norowpadding {
  --bs-gutter-x: 0;
}

html {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $midnight;
  background-color: #fff;
  line-height: 1.7;
  font-size: 1.125rem;
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: $midnight;

  &:hover {
    color: $black;
  }
}

body.navopen::before {
  background: rgba(0, 0, 0, .75);
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
}

.override-col-xl-8,
.overrideblog-col-xl-8 {
  width: 100% !important;
}

.paddedColumns {
  .row > .col-12 {
    padding: 20px 10px;
  }
}

.paddedColumnsSides {
  .row > .col-12 {
    padding: 0 30px;
  }
}

.paddedColumnsNarrow {
  .row > .col-12 {
    padding: 20px 30px;
  }
}

.paddedColumnsLarge {
  .row > .col-12 {
    padding: 80px 30px;
  }
}

.noTopPadding {
  padding-top: 0 !important;
}

@media (min-width: $desktop) {
  .paddedColumns {
    .row > .col-12 {
      padding: 40px 20px;
    }
  }
}

.alignContentTop {
  .row > .col-12 {
    align-self: flex-start;
  }
}

@media (min-width: $xx-large-desktop) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}

.midnight {
  color: $midnight !important;
}

@media only screen and (max-width: 991px) {
  .mobile-text-center {
    text-align: center;
  }

  .text-mobile-center {
    text-align: center;
  }

  .text-mobile-left {
    text-align: left;
  }
}

@media only screen and (min-width: $desktop) {
  .text-center {
    text-align: center;
  }
}

h2 {
  font-size: 2.375rem;
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
}

@media only screen and (min-width: $desktop) {
  h2 {
    font-size: 3.125rem;
  }
}

.text-serif {
  font-family: "Source Serif Pro", serif;
}

.text-sans {
  font-family: "Source Sans Pro", sans-serif !important;
}

.text-400 {
  font-weight: 400;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-800 {
  font-weight: 800;
}

.text-20 {
  font-size: 1rem !important;
  line-height: 1.4;
}


.text-24 {
  font-size: 1.25rem;
  line-height: 1.2;
}


@media only screen and (min-width: $desktop) {

  .text-20 {
    font-size: 1.25rem !important;
    line-height: 1.2;
  }

  .text-24 {
    font-size: 1.5rem;
    line-height: 1.4;
    margin-bottom: 2rem;
  }
}

.text-26 {
  font-size: 1.5rem;
  line-height: 1.625;
}

.text-32 {
  font-size: 1.75rem;
  line-height: 1.25;
}

@media only screen and (min-width: $desktop) {
  .text-32 {
    font-size: 2rem;
  }
}

.text-36 {
  font-size: 1.875rem;
}

.text-40 {
  font-size: 2.375rem;
  line-height: 1.3;
}

@media only screen and (min-width: $desktop) {
  .text-40 {
    font-size: 2.5rem;
    line-height: 1.4;
  }

  .text-36 {
    font-size: 2.25rem;
  }
}

.text-44 {
  font-size: 2.875rem;
}

.text-46 {
  font-size: 2.75rem;
}

.text-50 {
  font-size: 2.375rem;
}

@media only screen and (min-width: $desktop) {
  .text-50 {
    font-size: 3.125rem;
  }
}

.text-52 {
  font-size: 3.25rem;
  line-height: 1.2;
}

.text-55 {
  font-size: 3.438rem;
  line-height: 1.2;
}

.text-bold {
  font-weight: 700;
}

.text-aqua {
  color: #0081a6;
}

.text-lightblue {
  color: #2ec3e7;
}

.text-white {
  color: $white;
}


.text-333 {
  color: #333;
}

.text-bold-600 {
  font-weight: 600;
}

.text-bold-900 {
  font-weight: 900;
}

@media only screen and (max-width: $desktop) {
  .text-mobile-32 {
    font-size: 2rem;
  }

  .col-padding-16 {
    padding: 0 16px;
  }
}



@media only screen and (min-width: $large-desktop) {
  .text-padding-370 {
    padding: 0 370px;
  }

  .text-padding-340 {
    padding: 0 340px;
  }

  .text-padding-320 {
    padding: 0 320px;
  }

  .text-padding-310 {
    padding: 0 310px;
  }

  .text-padding-300 {
    padding: 0 300px;
  }

  .text-padding-290 {
    padding: 0 290px;
  }

  .text-padding-280 {
    padding: 0 280px;
  }

  .text-padding-250 {
    padding: 0 250px;
  }

  .text-padding-230 {
    padding: 0 230px !important;
  }

  .text-padding-220 {
    padding: 0 220px;
  }

  .text-padding-210 {
    padding: 0 210px;
  }

  .text-padding-190 {
    padding: 0 190px;
  }

  .text-padding-180 {
    padding: 0 180px;
  }

  .text-padding-170 {
    padding: 0 90px;
  }

  .text-padding-150 {
    padding: 0 150px;
  }

  .text-padding-140 {
    padding: 0 140px;
  }

  .text-padding-130 {
    padding: 0 130px;
  }

  .text-padding-large {
    padding: 0 150px;
  }

  .text-padding-127 {
    padding: 0 127px;
  }

  .text-padding-120 {
    padding: 0 120px;
  }

  .text-padding-110 {
    padding: 0 110px;
  }

  .text-padding-medium {
    padding: 0 100px;
  }

  .text-padding-90 {
    padding: 0 90px;
  }

  .text-padding-80 {
    padding: 0 80px;
  }

  .text-padding-70 {
    padding: 0 70px;
  }

  .text-padding-60 {
    padding: 0 60px;
  }

  .text-padding-47 {
    padding: 0 47px;
  }

  .text-padding-40 {
    padding: 0 40px;
  }

  .text-padding-29 {
    padding: 0 29px;
  }

  .text-padding-20 {
    padding: 0 20px;
  }

  .text-padding-right-170 {
    padding-right: 170px;
  }

  .text-padding-right-150 {
    padding-right: 150px;
  }

  .text-padding-right-130 {
    padding-right: 130px;
  }

  .m-mobileoverflow-scroll {
    overflow: visible;
  }
}

@media only screen and (min-width: $large-desktop) {
  .text-padding-180wide {
    padding: 0 180px;
  }

  .text-padding-170 {
    padding: 0 170px;
  }
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.text-padding-top-130 {
  padding-top: 75px !important;
}

.text-padding-top-120 {
  padding-top: 60px !important;
}

.text-padding-top-80 {
  padding-top: 48px !important;
}

.text-padding-top-mobile40 {
  padding-top: 40px !important;
}

.text-padding-top-40 {
  padding-top: 20px !important;
}

.text-padding-top-20 {
  padding-top: 20px !important;
}

@media only screen and (min-width: $desktop) {
  .text-padding-top-130 {
    padding-top: 130px !important;
  }

  .text-padding-top-120 {
    padding-top: 120px !important;
  }

  .text-padding-top-100 {
    padding-top: 100px !important;
  }

  .text-padding-top-80 {
    padding-top: 80px !important;
  }

  .text-padding-top-40 {
    padding-top: 40px !important;
  }
}

.text-padding-top-60 {
  padding-top: 60px !important;
}

.text-padding-top-10 {
  padding-top: 10px !important;
}

.text-padding-top-0 {
  padding-top: 0 !important;
}

.text-padding-right-19 {
  padding-right: 19px;
}

.text-padding-right-5 {
  padding-right: 5px;
}

.text-padding-bottom-250 {
  padding-bottom: 250px !important;
}

.text-padding-bottom-150 {
  padding-bottom: 70px !important;
}

.text-padding-bottom-110 {
  padding-bottom: 50px !important;
}

.text-padding-bottom-100 {
  padding-bottom: 50px !important;
}

.text-padding-bottom-80 {
  padding-bottom: 56px !important;
}

.text-padding-bottom-0 {
  padding-bottom: 0px !important;
}

@media only screen and (min-width: $desktop) {
  .text-padding-bottom-120 {
    padding-bottom: 120px !important;
  }

  .text-padding-bottom-150 {
    padding-bottom: 150px !important;
  }


  .text-padding-bottom-110 {
    padding-bottom: 110px !important;
  }

  .text-padding-bottom-100 {
    padding-bottom: 100px !important;
  }

  .text-padding-bottom-80 {
    padding-bottom: 80px !important;
  }
}

.text-padding-bottom-60 {
  padding-bottom: 60px !important;
}

.text-padding-bottom-50 {
  padding-bottom: 50px !important;
}

.text-padding-bottom-40 {
  padding-bottom: 20px;
}

.text-padding-bottom-35 {
  padding-bottom: 16px;
}

.text-padding-bottom-24 {
  padding-bottom: 24px !important;
}

.text-padding-bottom-10 {
  padding-bottom: 10px !important;
}

.text-margin-top-60 {
  margin-top: 60px;
}

.text-margin-top-40 {
  margin-top: 40px;
}

.text-margin-bottom-80 {
  margin-bottom: 80px;
}

.text-margin-bottom-70 {
  margin-bottom: 35px;
}

.text-margin-bottom-60 {
  margin-bottom: 60px;
}

.text-margin-bottom-50 {
  margin-bottom: 25px;
}

.text-margin-bottom-40 {
  margin-bottom: 40px;
}

.text-margin-bottom-32 {
  margin-bottom: 16px;
}

.text-margin-bottom-20 {
  margin-bottom: 24px;
}

.text-margin-bottom-16 {
  margin-bottom: 16px;
}


.text-margin-bottom-15 {
  margin-bottom: 15px;
}

.text-margin-bottom-12 {
  margin-bottom: 24px;
}

.text-margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: $desktop) {
  .text-margin-bottom-desk-32 {
    margin-bottom: 32px;
  }

  .text-margin-bottom-70 {
    margin-bottom: 70px;
  }

  .text-margin-bottom-50 {
    margin-bottom: 50px;
  }

  .text-padding-bottom-40 {
    padding-bottom: 40px;
  }

  .text-padding-bottom-35 {
    padding-bottom: 35px;
  }

  .text-margin-bottom-32 {
    margin-bottom: 32px;
  }

  .text-margin-bottom-20 {
    margin-bottom: 20px;
  }

  .text-margin-bottom-12 {
    margin-bottom: 12px;
  }
}

.m-mobileoverflow-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}

@media only screen and (min-width: $desktop) {
  .m-mobileoverflow-scroll {
    overflow: visible;

    img {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .text-padding-mobile-22 {
    padding: 0 22px;
  }

  .text-margin-bottom-mobile-20 {
    margin-bottom: 20px;
  }

  .text-padding-bottom-mobile-0 {
    padding-bottom: 0 !important;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .text-padding-right-100 {
    padding-right: 100px;
  }
}

.text-line-22 {
  line-height: 22px;
}

.text-hide-overflow {
  overflow: hidden;
}

.midnight-bg {
  background: $midnight;
  color: $white;

  &.gradient-bg {
    background: linear-gradient(180deg, #246885, $midnight 50%);
  }

  &.m-blog-gradient {
    padding-bottom: 220px;
    background: linear-gradient(180deg, #246885, $midnight 50%);
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-blog-gradient {
    padding-top: 70px !important;
  }
}

.topograph-bg {
  background-image: url("/images/home/topograph-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .m-workshop-checlist__image {
    margin-top: 30px;
  }
}

.m-topography-white {
  background-image: url("/images/topogophy-white-bg.jpg");
  background-repeat: no-repeat;
  background-position: center -220px;
  background-size: 400%;

  &.m-topography-white-large {
    background-image: url("/images/white-topography-large.jpg");
  }

  &.m-topography-serve {
    background-image: url("/images/white-topography-serve.jpg");
  }

  &.m-topography-assess {
    background-image: url("/images/white-topography-assess.jpg");
  }

  &.m-topography-media {
    background-image: url("/images/white-topography-media.jpg");
  }
}

@media only screen and (min-width: $desktop) {
  .m-topography-white {
    background-position: center;
    background-size: cover;
  }

  .topograph-bg {
    .m-workshop-checlist__image {
      margin-top: 0;
    }
  }
}

.topogrophy-light {
  background-image: url("/images/topogrophy-light.svg");
  background-repeat: no-repeat;
  background-size: 300%;
  position: relative;
  overflow: hidden;
  background-position: center;

  &::before {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), transparent);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
  }

  .container {
    position: relative;
  }
}

@media only screen and (min-width: $desktop) {
  .topogrophy-light {
    background-size: 130%;
  }
}

.m-horizontal-gradient {
  background: linear-gradient(270deg, #0089b2 0%, #131945);
  color: $white;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/images/home/topograph-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.desktop-only {
  display: none;
}

.large-desktop-only {
  display: none;
}

.desktop-flex-only {
  display: none;
}

.tablet-desktop-only {
  display: none;
}

.tablet-desktop-flex-only {
  display: none;
}

@media only screen and (min-width: $tablet) {
  .tablet-desktop-only {
    display: block;
  }

  .hide-tablet-dekstop {
    display: none;
  }

  .tablet-desktop-flex-only {
    display: flex;
  }
}

@media only screen and (min-width: $desktop) {
  .desktop-only {
    display: block;
  }

  .desktop-flex-only {
    display: flex;
  }

  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: $large-desktop) {
  .large-desktop-only {
    display: block;
  }

  .large-mobile-only {
    display: none;
  }
}

.btn {
  border-radius: 0;
}

.m-strong-link {
  color: $black;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.125rem;

  &:hover {
    color: $black;
  }
}

.m-standard-section {
  padding: 50px 0;
}

@media only screen and (min-width: $desktop) {
  .m-standard-section {
    padding: 100px 0;
  }
}

.m-standard-section-80 {
  padding: 50px 0;
}

.m-standard-section-60 {
  padding: 30px 0;
}

@media only screen and (min-width: $desktop) {
  .m-standard-section-80 {
    padding: 80px 0;
  }

  .m-standard-section-60 {
    padding: 60px 0;
  }
}

.m-standard-section__60-100 {
  padding: 60px 0 40px;
}

@media only screen and (min-width: $desktop) {
  .m-standard-section__60-100 {
    padding: 60px 0 100px;
  }
}

.m-short-section {
  padding: 50px 0;
}

.m-nobottom-section {
  padding-bottom: 0 !important;
}

/* Header and alerts */
header {
  background: $white;
  padding: 0 0 2px !important;
  border-bottom: 1px solid #a4a4a4;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
  position: fixed;
  height: 80px;
  align-content: center;
}

@media only screen and (min-width: 1399px) {
  header {
    padding: 2px 0 !important;
  }
}

.m-alert-background {
  background: #0081a6;
  padding: 10px 0;
  position: relative;
  z-index: 2000;
}

.m-alert-text {
  font-size: 1.125rem;
  color: $white;
  line-height: 25px;

  p {
    margin-bottom: 0;
  }

  a {
    color: #fff;
  }
}

main {
  padding-top: 78px;
  overflow: hidden;
}

@media only screen and (min-width: $desktop) {
  header {
    height: 81px;
  }

  .m-alert-text {
    font-size: 1.25rem;
  }

  main {
    padding-top: 75px;
  }
}

.m-header-nav {
  .m-header-nav__column {
    padding: 0;
  }

  .m-header-nav__buttons {
    padding: 6px 0;
    background: $white;
    position: relative;
  }
}

.m-navbar-container {
  padding: 16px 20px 55px;
}

@media only screen and (max-width: 991px) {
  .m-navbar-container {
    border-top: 1px solid #a4a4a4;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 1399px) {
  header {
    .container {
      max-width: 100%;
    }
  }

  .m-header-nav {
    .m-header-nav__buttons {
      border-bottom: 0;
      padding: 4px 0;
      z-index: 10;
    }
  }

  .navbar-collapse.show {
    max-height: 103vh;
    overflow-y: scroll;
    padding-top: 85px;
    margin-top: -85px;
    z-index: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-header-nav {
    nav.navbar {
      .navbar-collapse {
        padding: 20px 0;
      }
    }
  }

  .m-navbar-container {
    padding: 0;
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-header-nav {
    nav.navbar {
      .navbar-collapse {
        padding: 20px;
      }
    }
  }
}

.navbar-brand {
  padding-bottom: 0;
  padding-left: 10px;
  padding-top: 3px;

  img {
    width: 155px;
  }
}

@media only screen and (min-width: $desktop) {
  .navbar-brand {
    img {
      padding-right: 0;
    }
  }
}
/* Main nav */
.navbar-toggler {
  border: 0;
  border-right: 1px solid $mid-grey;
  border-radius: 0;
  color: $dark-blue;
  padding: 0 20px;

  &:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    height: 22px;
    width: 27px;
    background-image: url("/images/menu-close.svg");
  }

  &.collapsed {
    .navbar-toggler-icon {
      background-image: url("/images/menu-hamburger.svg");
    }
  }
}

.search-icon {
  height: 26px;
  width: 26px;
  background-image: url("/images/search-icon.svg");
  display: block;
  ackground-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

#searchbox {
  position: absolute;
  background: $white;
  transform: translateY(1px);
  margin-top: 9px;
  padding-top: 0;
}

@media only screen and (min-width: $desktop) {
  #searchbox {
    margin-top: 9px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  #searchbox {
    margin-top: 7px;
  }
}

.m-searchbox-container {
  padding: 20px;
}

.m-searchbox-input {
  width: calc(100% - 166px);
  padding: 9px;
}

@media only screen and (min-width: $x-large-desktop) {
  #searchbox {
    transition: none;
  }

  .m-searchbox-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      height: 100% !important;
      width: 300%;
      background: $white;
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    }
  }

  .m-searchbox-input {
    width: 780px;
  }
}

@media only screen and (min-width: $x-large-desktop) {

  .m-searchbox-input {
    width: 890px;
  }
}

#searchsubmit {
  padding: 10px 20px;
}

.navbar.navbar-expand-xxl {
  padding: 0;
  background: $white;
}

@media only screen and (min-width: $x-large-desktop) {
  .navbar.navbar-expand-xxl {
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
    background: transparent;
    padding-bottom: var(--bs-navbar-padding-y);
  }
}

#searchclose {
  height: 22px;
  width: 27px;
  background: url("/images/menu-close.svg") no-repeat;
  border: 0;
  display: inline-block;
  transform: translateY(-3px);
}

.nav-link {
  color: $midnight;
  font-weight: 400;

  &:hover,
  &:active {
    color: $dark-blue;
  }

  &.show {
    color: $dark-blue;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .nav-link {
    padding-right: .625rem !important;
    padding-left: .625rem !important;
  }
}

.dropdown-item {
  font-weight: 600;
  font-size: 1.125rem;
  color: $midnight;
  padding: 8px 0;
  line-height: 1.2;
  white-space: normal;

  &:hover,
  &:active,
  &:visited {
    color: $midnight;
    background: $white;
    text-decoration: underline;
  }
}

p.dropdown-content {
  color: #333;
  line-height: 1.25;

  &.dropdown-content-nomargin {
    margin-bottom: 0;
  }
}

.m-search-toggle {
  border: 0;
  padding: 7px 17px;
}

.m-navbar-content__infocolumn {
  background: $mid-grey;
  color: #333;
  padding: 32px 32px 32px 14px;
  display: none;
  font-size: 1.125rem;
  line-height: 1.4;

  .btn.btn-primary {
    color: $midnight;
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 600;

    &:hover,
    &:active,
    &:focus {
      color: $midnight;
      text-decoration: underline;
    }

    &:visited {
      color: $midnight;
    }
  }
}

p.header {
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-size: 1.375rem;
  margin-bottom: 6px;
  color: #131945;
}

p.subheader {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 8px;
  color: $midnight;
}

.m-navbar-content__linkcolumn {
  color: $dark-blue;
  padding: 0 0 15px;
}

@media only screen and (min-width: $x-large-desktop) {
  .m-navbar-content__linkcolumn {
    color: $dark-blue;
    padding: 32px;
  }
}

.dropdown-menu {
  border: 0;
}

.m-navbar-linkgroup__link.mobile-only {
  margin: 15px 0 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;

  .m-standard-button {
    padding: 10px 20px;
    margin-left: 0;
    font-size: 1.25rem;
  }

  .m-inverse-button {
    padding: 10px 20px;
    font-size: 1.25rem;
  }
}

.m-header-buttons__demoshop {
  .m-standard-button {
    margin: 0;
  }
}

.m-header-buttons__login {
  padding: 30px 0 0;
}

.m-navbar-linkgroup {
  display: flex;
  align-items: center;
  font-size: 1rem;

  .m-standard-button {
    padding: 9px 18px;
    font-size: 1.125rem;
  }

  .m-inverse-button {
    padding: 9px 18px;
    font-size: 1.125rem;
  }
}

.dropdown-toggle {
  &::after {
    vertical-align: initial;
    margin-left: 11px;
    border: 0;
    background: url("/images/mobile-nav-chevron.svg") no-repeat;
    height: 13px;
    width: 8px;
  }

  &.show {
    &::after {
      transform: rotate(90deg);
    }
  }
}

@media only screen and (min-width: $phone) {
  .m-navbar-linkgroup__link.mobile-only {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 25px;
  }

  .m-header-buttons__login {
    padding: 0;
  }
}

@media only screen and (max-width: 1399px) {
  .m-mobilenav-padfix {
    padding: 0;
  }

  .m-mobilenav-displayfix {
    display: none;
  }


  .navbar.navbar-expand-xxl {
    transform: translateY(6px);
  }
}


@media only screen and (min-width: $x-large-desktop) {
  .dropdown-toggle {
    position: relative;

    &::after {
      border-bottom: 2px solid transparent;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      left: 0;
      position: absolute;
      width: 100%;
      bottom: -11px;
      background: none;
      margin-left: 0;
    }

    &.show,
    &:hover,
    &.active {
      &::after {
        border-bottom: 3px solid $orange;
        content: "";
        display: block;
        transform: rotate(0deg);
      }
    }
  }

  .navbar-toggler {
    display: none;

    &.m-search-toggle {
      display: block;
      border-right: 1px solid #a4a4a4;
      margin-right: 17px;

      &::after {
        border-bottom: 4px solid #ef561f;
        content: "";
        display: block;
        transform: translateY(24px);
      }

      &.collapsed {
        &::after {
          border-bottom: 4px solid transparent;
        }
      }
    }
  }

  .m-header-nav__column {
    position: relative;
  }

  .nav-link {
    padding: .85rem;
  }

  .nav-item.dropdown {
    position: unset;
  }

  .dropdown-menu.show {
    left: -165px !important;
    top: 113px;
    width: 1312px;
    display: flex;
    border: 0;
    border-radius: 0;
    margin-top: 13px;
    padding: 0;

    &::before {
      content: "";
      height: 100%;
      display: block;
      position: absolute;
      left: 1px;
      width: 50%;
      background: $mid-grey;
      transform: translateX(-100%);
      border-bottom: 2px solid $mid-grey;
    }

    &::after {
      content: "";
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      width: 150vw;
      background: $white;
      transform: translateX(-50vw);
      border-bottom: 2px solid $mid-grey;
      z-index: -1;
      box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    }
  }

  .m-navbar-content__infocolumn {
    display: block;
  }

  nav.navbar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 170px;
    padding-bottom: var(--bs-navbar-padding-y);
    z-index: 10;

    .dropdown-menu {
      position: absolute;
      left: 0;
      margin: 0;
      transform: translateY(-15px);
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .nav-link {
    padding: 1rem;
  }

  .dropdown-toggle {

    &.show,
    &:hover,
    &.active {
      &::after {
        bottom: -8px;
      }
    }
  }

  nav.navbar {
    .dropdown-menu {
      transform: translateY(-13px);
    }
  }
}

/* Search */
.m-header-logosearch__search {
  text-align: right;
}

/* Content sections */
.m-content-section {
  padding: 40px 0;
  background: $white;
  position: relative;
}

/* Home hero */
.m-home-hero {
  h1 {
    font-size: 3.25rem !important;
    font-weight: 900 !important;
    line-height: 1.1 !important;
  }

  .m-home-hero__stat-image {
    position: absolute;
    right: 10px;
    bottom: 50%;
    max-width: 30%;
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .m-content-hero__content-media .m-content-hero__content {
    padding-top: 54px !important;
    padding-bottom: 30px !important;
  }
}

@media only screen and (min-width: $phone) {
  .m-home-hero {
    .m-home-hero__stat-image {
      bottom: 50%;
      max-width: 30%;
      display: block;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .m-home-hero {
    .m-home-hero__stat-image {
      bottom: 35%;
      max-width: 30%;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-home-hero {

    .m-home-hero__stat-image {
      right: 30px;
      bottom: -15%;
      max-width: 25%;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-home-hero {

    h1 {
      font-size: 4.5rem !important;
    }

    .m-home-hero__container {
      padding-top: 100px;
      padding-bottom: 70px;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-home-hero {
    .m-home-hero__stat-image {
      max-width: 100%;
    }
  }
}

/* Content hero */
/* content hero */
/* Content hero */
.m-content-hero {
  color: #041e42;
  position: relative;
  margin-top: 0;

  .m-content-hero__container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;


    .m-content-hero__image-container {
      order: 2;
      height: 450px;
      position: relative;
    }

    .m-content-hero__image {
      height: 100%;
      position: absolute;
      right: -60px;
    }
  }

  .m-content-hero__content {
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 68px 30px 60px;
    background: #e9e9e9;
  }

  h1 {
    font-family: "Source Serif Pro", serif;
    font-size: 2.625rem;
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.3;
  }

  .m-standard-button {
    margin-left: 0;
    display: inline-block;
    padding: 14px 58px;
  }
}

@media only screen and (max-width: 991px) {
  .m-content-hero {
    .m-content-hero__content {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: $phone) {
  .m-content-hero {
    .m-content-hero__container {
      .m-content-hero__image-container {
        order: 2;
        height: 450px;
        position: relative;
      }

      .m-content-hero__image {
        height: auto;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-content-hero {
    color: #041e42;
    position: relative;
    height: 560px;
    background: #eef0f2;

    .m-content-hero__container {
      height: 100%;
      justify-content: center;

      .m-content-hero__image-container {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 80%;
          height: 100%;
          background: linear-gradient(90deg,#eef0f2,#e3e4e4,#e3e4e4,transparent);
          z-index: 1;
          transform: translateX(-20vw);
        }
      }

      .m-content-hero__image {
        position: relative;
        top: 0;
        right: 0;
        left: auto !important;
        height: 100%;
        width: auto;
      }
    }

    .m-content-hero__content {
      text-align: left;
      padding: 0;
      background: none;

      .col-12 {
        padding-right: 0;
      }
    }

    h1 {
      font-size: 2.8rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.25;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-content-hero {
    height: 660px;

    .m-content-hero__container {
      h1 {
        font-size: 3.125rem;
      }

      .m-content-hero__image-container {
        &::before {
          transform: translateX(-25vw);
        }
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-content-hero {
    .m-content-hero__container {
      .m-content-hero__image-container {
        &::before {
          transform: translateX(-30vw);
        }
      }
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-content-hero {
    .m-content-hero__container {
      .m-content-hero__image-container {
        &::before {
          transform: translateX(-25vw);
        }
      }
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-content-hero {
    .m-content-hero__container {
      .m-content-hero__image-container {
        &::before {
          width: 60%;
          transform: translateX(-60%);
        }
      }
    }
  }
}

/* pie chart */
.skill-item {
  position: relative;
  max-width: 250px;
  width: 100%;
  margin: 0 auto 30px;
  color: #555;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  margin-bottom: 27px;
}

.skill-item .chart,
.skill-item .chart canvas {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90% !important;
  height: 90% !important;
}

.skill-item .chart::before {
  content: "";
  width: 0;
  height: 100%;
}

.skill-item .chart::before,
.skill-item .percent {
  display: inline-block;
  vertical-align: middle;
}

.skill-item .percent {
  position: relative;
  line-height: 1;
  font-size: 40px;
  font-weight: 900;
  z-index: 2;
}

.skill-item .percent::after {
  content: attr(data-after);
  font-size: 20px;
}

.m-pie-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  height: 65%;
  width: 65%;
  z-index: 1;
  border-radius: 50%;
}

.m-pie-brandimg {
  position: absolute;
  z-index: 1;
  width: 30%;
  right: 0;
  bottom: 0;
}

.m-chart-block {
  width: 50%;
  max-width: 100%;
  padding: 0;
  display: none;

  &:nth-child(1),
  &:nth-child(2) {
    display: block;
  }
}

@media only screen and (min-width: $tablet) {
  .m-chart-block {
    width: 50%;
    padding: 50px;
    display: block;
  }
}

@media only screen and (min-width: $desktop) {
  .m-chart-block {
    width: 20%;
    padding: 30px 30px 60px;
  }

  .m-pie-brandimg {
    width: auto;
    right: -10px;
    bottom: -10px;
  }
}

/* Statistics items */
.m-statistics-group .m-statistics-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
}

.m-statistics-group .m-statistics-item {
  flex: 1 1 100%;
}

.m-statistics-group .m-statistics-item .m-statistics-item--stat {
  font-size: 3rem;
  font-weight: 700;
}

.m-statistics-group .m-statistics-item .m-statistics-item--description {
  color: #0089b2;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.3;
}

@media (min-width: $desktop) {
  .m-statistics-group .m-statistics-item .m-statistics-item--stat {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .m-statistics-group .m-statistics-items {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .m-statistics-group .m-statistics-item {
    padding: 20px 15px;
  }
}

@media (min-width: $large-desktop) {
  .m-statistics-group .m-statistics-item .m-statistics-item--stat {
    font-size: 3rem;
    font-weight: 700;
  }

  .m-statistics-group .m-statistics-item {
    padding: 20px;
  }
}

.m-practice-h2subhead {
  font-size: 2.375rem;
  line-height: 46px;
  margin-bottom: 1.5rem;
}

.m-practice-bullets {
  padding-bottom: 70px;

  h3 {
    margin-bottom: 30px;
  }

  .col-lg-6 {
    padding-right: 0 !important;
  }
}

.m-timeline-section {
  padding-top: 10px;

  .text-40 {
    font-size: 2rem;
    text-align: center;
  }
}

.m-practice-subheaddesc {
  font-size: 1.25rem;
  line-height: 28px;
}

@media only screen and (min-width: $large-desktop) {
  .m-practice-h2subhead {
    font-size: 2.875rem;
    line-height: 57px;
    margin-bottom: 1.5rem;
  }

  .m-practice-subheaddesc {
    padding: 0 180px;
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .m-practice-bullets {

    .col-lg-6 {
      padding-right: 100px !important;
    }
  }

  .m-timeline-section {
    padding-top: 80px;

    .text-40 {
      font-size: 2.375rem;
    }
  }
}
/* Practice tiles */
.m-practice-tiles {
  margin-top: 32px;
  margin-bottom: 40px;

  .m-practice-tile__container {
    margin: 0 10px;
    background: $white;
    height: 100%;
  }

  .m-practice-tile__content {
    padding: 73px 45px 19px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
    height: 100%;
    line-height: 1.2;

    p {
      margin-bottom: 0;
      font-size: 1.25rem;
    }

    img {
      max-height: 233px;
      margin-bottom: 40px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-practice-tiles {
    .m-practice-tile__container {
      margin: 0 10px 30px;
    }

    .m-practice-tile__content {
      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-practice-tiles {
    .m-practice-tile__content {
      p {
        padding: 0 25px;
      }
    }
  }
}


.m-practices-padded {
  font-size: 1.5rem;
  line-height: 1.4;
}

@media only screen and (min-width: $x-large-desktop) {
  .m-practices-padded {
    padding: 0 80px;
  }
}

.m-practices-social {
  font-size: 2rem;
  margin-top: 8px;

  a {
    padding: 0 12px;
  }

  span {
    color: #ef561f;
  }
}

.m-social-hr {
  padding-top: 0;
  position: relative;
  margin-top: 50px;

  &::before {
    content: "";
    position: relative;
    top: 0;
    height: 1px;
    width: 85%;
    border-top: 1px solid #b3b3b3;
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    padding-top: 50px;
  }

  .text-32 {
    font-size: 1.625rem;
  }
}

@media only screen and (min-width: $phone) {
  .m-social-hr {
    padding-top: 0;
    position: relative;

    &::before {
      content: none;
    }

    .container {
      border-top: 1px solid #b3b3b3;
      padding-top: 100px;
    }

    .text-32 {
      font-size: 2rem;
    }
  }
}

.m-practices-arrow {
  position: relative;

  &::before {
    background: url("/images/practices/grey-arrow-background.svg") no-repeat;
    background-size: 100%;
    content: "";
    display: block;
    width: 150%;
    height: 1000px;
    position: absolute;
    z-index: -1;
    bottom: 45%;
    left: -100%;
    transform: translateX(50%);
  }

  &.m-practices-arrow-reliability {
    &::before {
      bottom: 15%;
    }
  }

  &.m-practices-arrow-validation {
    &::before {
      bottom: -10%;
    }
  }

  &.m-practices-arrow-scales {
    &::before {
      bottom: -70%;
    }
  }

  &.assessment-mobile-only {
    &::before {
      bottom: -200%;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .m-practices-arrow {
    &.m-practices-arrow-scales {
      &::before {
        bottom: -80%;
      }
    }

    &.m-practices-arrow-validation {
      &::before {
        bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-practices-arrow {
    &.m-practices-arrow-scales {
      margin-bottom: 30px;

      &::before {
        bottom: -40%;
      }
    }

    &.m-practices-arrow-validation {
      &::before {
        bottom: 20%;
      }
    }

    &.assessment-mobile-only {
      &::before {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-practices-arrow {
    &.m-practices-arrow-scales {
      &::before {
        bottom: -10%;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-practices-arrow {
    &.m-practices-arrow-scales {
      &::before {
        bottom: 16%;
      }
    }

    &.m-practices-arrow-validation {
      &::before {
        bottom: 23%;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-practice-tiles {
    .m-practice-tile__content {
      padding: 73px 50px 19px;
      height: 100%;
    }
  }
}

/* Practices ebook */

.m-practices-ebook {
  max-width: 100vw;
  overflow: hidden;
  margin-bottom: 50px;
}

.m-practices-ebook__content {
  padding-top: 46px;
  order: 2;
  text-align: center;

  p {
    margin-bottom: 1rem;
    line-height: 50px;
  }

  .m-standard-button {
    display: inline-block;
    margin-top: 16px !important;
    text-align: center;
  }
}

@media only screen and (min-width: $desktop) {
  .m-practices-ebook__content {
    text-align: left;

    h2 {
      text-align: center;
    }
  }
}


.m-practices-ebook__image {
  position: relative;
  padding: 20px 0;

  &::before {
    background: #e6e6e6;
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    left: 18%;
    top: 0;
  }

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-practices-ebook__content {
    order: 0;
    text-align: left;
    padding-right: 60px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-practices-ebook__content {
    padding-right: 50px;
    padding-left: 100px;
  }
}


/* Horizontal timeline */
.m-horizontal-timeline {
  display: block;
  margin-top: 50px;
}

@media only screen and (min-width: $large-desktop) {
  .m-horizontal-timeline {
    margin-top: 100px;
  }
}

.m-horizontal-timeline__box {
  width: 100%;
  position: relative;
  margin-bottom: 80px;

  .arrow {
    &::after {
      //down arrow
      color: $white;
      padding: 20px;
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 50% 100%, 0 calc(100% - 20px));
      position: absolute;
      top: calc(100% - 1px);
      content: "";
      width: 100%;
      background-color: #e6e6e6;
      transition: all .2s ease-in;
      transform: translateX(-50%);
    }


    &.green {
      &::after,
      &::before {
        background-color: #009f43;
      }
    }

    &.blue {
      &::after,
      &::before {
        background-color: #0086d6;
      }
    }

    &.orange {
      &::after,
      &::before {
        background-color: #fc4c02;
      }
    }

    &.purple {
      &::after,
      &::before {
        background-color: #963cdb;
      }
    }

    &.red {
      &::after,
      &::before {
        background-color: #d32f37;
      }
    }
  }

  &:last-child {
    padding-bottom: 120px;

    &::before {
      content: "";
      background: url("/images/practices/timeline-green-icon.svg") no-repeat;
      width: 70px;
      height: 100px;
      display: block;
      position: absolute;
      left: 50%;
      top: 90%;
      transform: translateX(-50%);
    }

    &.model-icon {
      &::before {
        background: url("/LeadershipChallenge/media/media/practices/timeline-green-icon-disabled.svg") no-repeat;
      }

      &.final {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-green-icon.svg") no-repeat;
        }
      }
    }

    &.vision-icon {
      &::before {
        background: url("/LeadershipChallenge/media/media/practices/timeline-blue-icon-disabled.svg") no-repeat;
      }

      &.final {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-blue-icon.svg") no-repeat;
        }
      }
    }

    &.challenge-icon {
      &::before {
        background: url("/LeadershipChallenge/media/media/practices/timeline-orange-icon-disabled.svg") no-repeat;
      }

      &.final {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-orange-icon.svg") no-repeat;
        }
      }
    }

    &.enable-icon {
      &::before {
        background: url("/LeadershipChallenge/media/media/practices/timeline-purple-icon-disabled.svg") no-repeat;
      }

      &.final {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-purple-icon.svg") no-repeat;
        }
      }
    }

    &.encourage-icon {
      &::before {
        background: url("/LeadershipChallenge/media/media/practices/timeline-red-icon-disabled.svg") no-repeat;
      }

      &.final {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-red-icon.svg") no-repeat;
        }
      }
    }
  }
}

.m-horizontal-timeline__box-content {
  width: 100%;
  background: #e6e6e6;
  text-align: center;
  font-size: 1.25rem;
  position: relative;
  padding: 20px 18px;
  color: $white;
  font-weight: 600;
  line-height: 1.25;
  transition: all .2s ease-in;

  &.green {
    background: #009f43;
  }

  &.blue {
    background: #0086d6;
  }

  &.orange {
    background: #fc4c02;
  }

  &.purple {
    background: #963cdb;
  }

  &.red {
    background: #d32f37;
  }
}

@media only screen and (max-width: 991px) {
  .m-horizontal-timeline__box {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 100%;
      width: 2px;
      transform: translate(-1px, 100%);
      border-left: 2px solid #e6e6e6;
      z-index: -1;
    }

    &:last-child {
      &::after {
        transform: translate(-1px, 20%);
      }
    }

    &.green {
      &::after {
        border-color: #009f43;
      }
    }

    &.blue {
      &::after {
        border-color: #0086d6;
      }
    }

    &.orange {
      &::after {
        border-color: #fc4c02;
      }
    }

    &.purple {
      &::after {
        border-color: #963cdb;
      }
    }

    &.red {
      &::after {
        border-color: #d32f37;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-horizontal-timeline__section {
    overflow: hidden;
  }

  .m-horizontal-timeline {
    display: flex;
    justify-content: space-between;
    height: 400px;
  }

  .m-horizontal-timeline__box {
    height: 170px;
    transform: translateY(-45px);
    margin-bottom: 0;

    &.green {
      &::after {
        border-color: #009f43;
      }
    }

    &.blue {
      &::after {
        border-color: #0086d6;
      }
    }

    &.orange {
      &::after {
        border-color: #fc4c02;
      }
    }

    &.purple {
      &::after {
        border-color: #963cdb;
      }
    }

    &.red {
      &::after {
        border-color: #d32f37;
      }
    }

    .arrow {
      &::before {
        width: 2px;
        height: 20px;
        position: absolute;
        content: "";
        background: #e6e6e6;
        top: calc(100% + 38px);
        transform: translateX(-50%);
      }

      &::after {
        transform: translateX(0);
      }

      &.green {
        &::before {
          background-color: #009f43;
        }
      }

      &.blue {
        &before {
          border-color: #0086d6;
        }
      }

      &.orange {
        &before {
          border-color: #fc4c02;
        }
      }

      &.purple {
        &before {
          border-color: #963cdb;
        }
      }

      &.red {
        &before {
          border-color: #d32f37;
        }
      }
    }

    &:nth-child(even) {
      transform: translateY(240px);

      .m-horizontal-timeline__box-content {
        padding: 0 18px 20px;
      }

      .arrow {
        &::after {
          clip-path: polygon(50% 0, 100% 20px, 100% 100%, 0 100%, 0 20px);
          top: 0;
          transform: translateY(calc(-100% + 1px));
        }

        &::before {
          top: -56px;
        }
      }

      &::after {
        top: -57px;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 120%;
      height: 2px;
      border-bottom: 2px solid #e6e6e6;
      position: absolute;
      top: calc(100% + 58px);
      transform: translateX(-10%);
      transition: all .2s ease-in;
    }

    &:first-child {
      &::after {
        width: 300%;
        transform: translateX(-50%);
      }
    }

    &:last-child {
      padding-bottom: 0;

      &::before {
        content: "";
        background: url("/images/practices/timeline-green-icon.svg") no-repeat;
        width: 70px;
        height: 100px;
        display: block;
        position: absolute;
        left: 37%;
        top: -183px;
        transform: none;
      }

      &.model-icon {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-green-icon-disabled.svg") no-repeat;
        }

        &.final {
          &::before {
            background: url("/LeadershipChallenge/media/media/practices/timeline-green-icon.svg") no-repeat;
          }
        }
      }

      &.vision-icon {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-blue-icon-disabled.svg") no-repeat;
        }

        &.final {
          &::before {
            background: url("/LeadershipChallenge/media/media/practices/timeline-blue-icon.svg") no-repeat;
          }
        }
      }

      &.challenge-icon {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-orange-icon-disabled.svg") no-repeat;
        }

        &.final {
          &::before {
            background: url("/LeadershipChallenge/media/media/practices/timeline-orange-icon.svg") no-repeat;
          }
        }
      }

      &.enable-icon {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-purple-icon-disabled.svg") no-repeat;
        }

        &.final {
          &::before {
            background: url("/LeadershipChallenge/media/media/practices/timeline-purple-icon.svg") no-repeat;
          }
        }
      }

      &.encourage-icon {
        &::before {
          background: url("/LeadershipChallenge/media/media/practices/timeline-red-icon-disabled.svg") no-repeat;
        }

        &.final {
          &::before {
            background: url("/LeadershipChallenge/media/media/practices/timeline-red-icon.svg") no-repeat;
          }
        }
      }


      &::after {
        width: 300%;
      }
    }
  }

  .m-horizontal-timeline__box-content {
    width: 120%;
    height: 100%;
    transform: translateX(-10%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 18px 0;

    p {
      margin: 0;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-horizontal-timeline__box-content {
    font-size: 1.5rem;
  }
}

/* Assessments */
.m-assessment-list {
  margin: 24px 0 90px;
  align-items: center;

  .m-assessment-list__image {
    position: relative;

    img {
      width: 100%;
    }

    .m-assessment-list__image-text {
      font-size: 1rem;
      font-weight: 800;
      line-height: 1.4;
      margin: 0;
      padding: 2px 8px;
      position: absolute;
      right: 16px;
      bottom: 16px;

      &.blue {
        color: #007495;
        background: #e7eff0;
      }

      &.red {
        color: #bd3200;
        background: #fcddd2;
      }
    }

    .m-assessment-list__image-thumbnail {
      position: absolute;
      bottom: -40px;
      text-align: left;
      padding: 0 30px;

      img {
        width: 55%;
        box-shadow: 0 20px 32px rgba(0, 0, 0, .16);
      }
    }
  }

  .m-assessment-list__content {
    padding-right: 14px;
    padding-left: 20px;

    h3 {
      font-weight: 800;
      font-size: 1.75rem;
      line-height: 1.35;
      margin-bottom: 8px;
      margin-top: 72px;
    }

    .m-standard-button {
      margin: 0;
      display: inline-block;
    }
  }

  .m-assessment-list__content-indent {
    padding-left: 32px;
    border-left: 4px solid #25cae1;
    font-size: 1.25rem;
    line-height: 1.25;

    p {
      margin-bottom: 12px;

      strong {
        margin-bottom: .75rem;
      }
    }

    ul {
      padding-left: 28px;
      margin-bottom: 2rem;
    }

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &::marker {
        color: #0081a6;
      }
    }
  }

  .m-assessment-list__list {
    li {
      position: relative;
      list-style-type: none;

      &::before {
        position: absolute;
        left: -28px;
        content: "";
        background: url("/images/assessments-overview/assessment-checkmark.svg") no-repeat;
        width: 21px;
        height: 21px;
        display: block;
        top: 3px;
      }
    }
  }
}

.m-assessment-list__imagecontainer {
  padding: 0;
}

.m-assessment-list__inverted {

  .m-assessment-list__image {
    padding-right: 0;

    .m-assessment-list__image-thumbnail {
      right: 0;
      text-align: right;
    }

    .m-assessment-list__image-text {
      right: auto;
      left: 16px;
    }
  }
}

.m-assess-tiles-gradient {
  position: relative;
  padding-bottom: 0;

  .m-practice-tile__container {
    padding-bottom: 0 !important;
  }

  &.m-assess-tiles-gradient::before {
    content: "";
    background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945 120%);
    display: block;
    width: 100%;
    height: calc(100% - 180px);
    position: absolute;
    z-index: -1;
  }

  .glider-track {
    padding: 0;
  }

  .m-carousel-controls {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 12px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-pt-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 30px;
    border: 0;
  }

  .glider-pt-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 30px;
    border: 0;
  }
}

.m-assessment-negativetop {
  margin-top: -110px !important;
  padding-top: 200px !important;
}

@media only screen and (min-width: $large-desktop) {
  .m-assessment-list {
    margin: 60px 0 120px;
    align-items: center;

    .m-assessment-list__image {
      padding-right: 60px;

      img {
        width: 100%;
      }

      .m-assessment-list__image-text {
        font-size: 2rem;
        right: 92px;
        bottom: 32px;
      }

      .m-assessment-list__image-thumbnail {
        position: absolute;
        bottom: -70px;
        padding: 0 60px;

        img {
          width: auto;
          box-shadow: 0 20px 32px rgba(0, 0, 0, .16);
        }
      }
    }

    .m-assessment-list__content {
      padding-right: 70px;
      padding-left: 0;

      h3 {
        font-weight: 800;
        font-size: 2rem;
        margin-top: 0;
      }

      .m-standard-button {
        margin: 4px 0 0;
        display: inline-block;
      }
    }

    .m-assessment-list__content-indent {
      padding-left: 32px;
      border-left: 4px solid #25cae1;
      font-size: 1.25rem;
      line-height: 1.25;

      ul {
        padding-left: 28px;
        margin-bottom: 2rem;
      }

      li {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &::marker {
          color: #0081a6;
        }
      }
    }

    .m-assessment-list__list {
      li {
        position: relative;
        list-style-type: none;

        &::before {
          position: absolute;
          left: -28px;
          content: "";
          background: url("/images/assessments-overview/assessment-checkmark.svg") no-repeat;
          width: 21px;
          height: 21px;
          display: block;
          top: 3px;
        }
      }
    }
  }

  .m-assessment-list__inverted {
    .m-assessment-list__content {
      padding-left: 40px;
      padding-right: 45px;
    }

    .m-assessment-list__imagecontainer {
      order: 2;
    }

    .m-assessment-list__image {
      padding-right: 0;
      padding-left: 60px;

      .m-assessment-list__image-thumbnail {
        right: 0;
      }

      .m-assessment-list__image-text {
        right: auto;
        left: 92px;
      }
    }
  }
}



.m-assessment-list__container-fluid {
  padding: 0;
  overflow: hidden;

  .m-assessment-list__container {
    padding: 0;
    margin: 0;
    max-width: 100% !important;

    h2 {
      font-size: 2.375rem;
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {

  .m-assessment-list__container-fluid {
    .m-assessment-list__container {
      margin: auto;
      max-width: 1440px !important;

      h2 {
        font-size: 3.125rem;
      }
    }
  }
}

.m-lpi-book {
  &.m-practices-arrow {
    &::before {
      bottom: -15%;
    }
  }
}

.m-lpi-book__button {
  text-align: center;
  margin-bottom: 12px;

  p {
    font-size: 1.25rem;
    line-height: 28px;
  }

  .m-standard-button {
    display: inline-block;
    margin-bottom: 8px;
  }

  .m-inverse-button {
    display: inline-block;
    margin-bottom: 8px;
  }
}

.m-lpi-book__image {
  text-align: center;

  img {
    transform: translateX(-25px);
  }
}

.m-lpibook-h3 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 700;
  font-family: "Source Serif Pro", serif;
  margin-top: 40px;
}

@media only screen and (min-width: $desktop) {
  .m-lpi-book {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    &.m-practices-arrow {
      &::before {
        bottom: -5%;
      }
    }

    .m-lpi-book__image {
      order: 2;
    }

    .m-lpi-book__button {
      max-width: 330px;

      &.order1 {
        order: 1;
      }

      &.order3 {
        order: 3;
      }

      p {
        font-size: 1.5rem;
        line-height: 36px;
      }
    }
  }

  .m-lpibook-h3 {
    margin-top: 64px;
    font-size: 2rem;
    line-height: 42px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-lpi-book {
    &.m-practices-arrow {
      &::before {
        height: 1150px;
        bottom: 60%;
      }
    }
  }
}
/* Paralax content */
.m-paralax-section {
  background: linear-gradient(#fff, #bde5ee, #0089b2, #131945);

  .m-paralax-content {
    position: relative;
    padding-bottom: 280px;
  }

  .m-paralax-background {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -60px);

    img {
      max-width: 340%;
      left: 50%;
      transform: translateX(-30%);
    }
  }

  .m-paralax-foreground {
    position: absolute;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .m-paralax-section {
    margin-bottom: 18px;

    .m-paralax-foreground {
      top: auto !important;
      bottom: -50px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  /* Paralax content */
  .m-paralax-section {

    .m-paralax-content {
      padding-bottom: 400px;
    }

    .m-paralax-background {
      transform: translateX(-50%);
      width: 80%;

      img {
        transform: none;
      }
    }

    .m-paralax-foreground {
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      top: 200px;
    }
  }
}

/* Video overlay */

.m-video-overlay__container {
  max-width: 100%;
  width: 100%;
  transition: all .5s ease-in;

  .m-video-close {
    display: none;
    position: absolute;
    right: 0;
    z-index: 10;
    margin-top: -35px;
    background: transparent;
    border: 0;
    color: $midnight;
    font-weight: 600;
  }

  &.active {
    width: 100%;
    position: relative;
    z-index: 10;

    .m-video-close {
      display: block;
    }
  }
}

.m-video-overlay {
  position: relative;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  .m-video-overlay__overlay {
    background: $midnight;
    color: $white;
    padding: 65px;
  }
}

@media only screen and (max-width: 991px) {
  .m-video-section {
    .container {
      max-width: 100%;

      .m-video-overlay {
        padding: 0;

        img {
          width: 156px;
        }

        p {
          font-size: 1.25rem;
          line-height: 1.3;
        }

        .m-video-overlay__overlay {
          padding: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-video-section {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;

    .container {
      max-width: 100%;
      padding: 0;
    }
  }

  .m-video-overlay__container {
    width: 70%;
    text-align: left;

    &.active {
      width: 100%;
      position: relative;
      z-index: 10;
    }
  }

  .m-video-overlay {
    position: relative;

    .m-video-overlay__overlay {
      position: absolute;
      right: 25px;
      width: 40%;
      top: 50%;
      transform: translateY(-35%);
      background: $midnight;
      color: $white;
      padding: 65px;
      text-align: left;

      p {
        font-size: 1.5rem;
        line-height: 1.4;
      }
    }

    img {
      max-width: 180px;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-video-section {
    .container {
      max-width: 1440px;
    }
  }

  .m-video-overlay {
    img {
      max-width: 220px;
    }
  }
}

/* TLC Icons */
.m-practice-header {
  font-weight: 900;
  font-family: "Source Serif Pro", serif;
  font-size: 2.625rem;
  margin-bottom: 20px;
}

@media only screen and (min-width: $desktop) {
  .m-practice-header {
    margin-top: -30px;
    font-size: 3.125rem;
  }
}

.m-tlc-icons {
  div {
    padding: 25px 55px 0;
    text-align: center;

    p {
      font-size: 1.125rem;
      line-height: 22px;
    }

    img {
      max-width: 150px;
      transition: all .3s ease-in;
    }

    &.active {
      img {
        transform: translateY(-40px);
      }

      p {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .tlc-icon-carousel {
    padding: 50px 30px !important;

    .text-44 {
      font-size: 2.125rem;
    }

    .text-24 {
      font-size: 1.125rem;
      line-height: 31px;
    }

    .m-carousel-controls {
      display: flex;
      justify-content: center;
      margin-top: 35px;
      margin-bottom: 30px;
      align-items: center;
    }

    .glider-dots {
      margin: 0;
    }

    .glider-dot {
      border: 2px solid $white;
      background: transparent;
      margin: 7px 10px;
      width: 20px;
      height: 20px;

      &.active {
        background: $white;
      }
    }

    .glider-ti-prev {
      background: url("/images/chevron-white-left.svg") no-repeat;
      width: 20px;
      height: 25px;
      position: relative;
      top: 0;
      left: auto;
      margin-right: 15px;
      border: 0;
    }

    .glider-ti-next {
      background: url("/images/chevron-white-right.svg") no-repeat;
      width: 20px;
      height: 25px;
      position: relative;
      top: 0;
      right: auto;
      margin-left: 15px;
      border: 0;
    }

    .m-tlc-icons div.active img {
      transform: none !important;
    }

    .m-tlc-icons div {
      padding: 25px 15px 0 !important;
      align-content: flex-start;

      img {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-practice-header__content {
    margin-bottom: 25px;
  }

  .m-tlc-icons {
    display: flex;
    flex-direction: row;

    div {
      padding: 45px 5px 0;
      width: 20%;

      p {
        font-weight: 700;
        transform: translateY(-60px);
        opacity: 0;
        transition: all .3s ease-in;
      }

      img {
        max-width: 250px;
        transition: all .3s ease-in;
        padding: 0 19px 30px;
      }

      &.active {
        img {
          transform: translateY(-40px);
        }

        p {
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-practice-header__content {
    padding: 0 120px;
  }

  .m-tlc-icons {
    div {
      padding: 45px 0 0;
    }

    img {
      padding: 0 38px 30px !important;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-practice-header__content {
    padding: 0 200px;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-tlc-icons {
    padding: 0 100px;
  }
}

.m-authors-approach {
  position: relative;
  padding: 50px 0 0;
  display: flex;
  flex-direction: column;

  .text-40 {
    font-size: 2rem;
    line-height: 1.3;
  }

  .text-24 {
    font-size: 1.375rem;
  }

  .m-authors-approach__image {
    text-align: center;
    order: 2;
    position: relative;

    img {
      max-width: 130%;
      position: relative;
      left: 50%;
      transform: translateX(-45%);
    }
  }
}

.m-authors-approach__quote {
  position: relative;
  line-height: 50px;

  .quot-left {
    position: absolute;
    left: -20px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-authors-approach {
    position: relative;
    padding: 130px 0;
    display: block;

    .text-40 {
      font-size: 2.5rem;
    }

    .text-24 {
      font-size: 1.5rem;
    }

    .m-authors-approach__image {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 61%;
      transform: translateX(-100px);

      img {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-authors-approach {
    overflow: hidden;

    .text-40 {
      font-size: 2.5rem;
    }
  }
}

/* Evidence */
.m-evidence-tile {
  margin: 44px 8px 16px;
  padding: 32px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
  background: $white;
  text-align: center;

  img {
    width: 136px;
    margin-bottom: 16px;
  }

  h3 {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: "Source Serif Pro", serif;
  }

  .m-standard-button {
    padding: 10px 20px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-evidence-tile {
    padding: 32px 16px 64px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-evidence-tile {
    padding: 32px 32px 64px;
  }
}

.m-evidence-validity {
  padding: 30px 0 22px;
}

.m-evidence-validity__image {
  img {
    max-width: 100%;
  }
}

@media only screen and (min-width: $desktop) {
  .m-evidence-validity {
    padding: 30px 49px 22px;
  }

  .m-evidence-validity__image {
    padding: 30px;

    img {
      max-width: 100%;
    }
  }
}

.m-evidence-icons {
  margin: 30px auto 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  div {
    padding: 0 12px;
    width: 33.3333%;

    img {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .m-evidence-icons {
    div {
      width: 20%;
      padding: 30px 10px 0;
    }
  }
}


@media only screen and (min-width: $desktop) {
  .m-evidence-icons {
    margin: 30px auto 60px;

    div {
      width: 20%;
      padding: 10px 0 0;

      img {
        padding: 0 30px 30px;
      }
    }
  }
}

.m-reliability-table {
  padding: 0;
  margin: 60px 0;

  table {
    width: 100%;
    background: $white;

    th {
      padding: 15px;
      white-space: nowrap;
    }

    td {
      color: #333;
      font-size: 1.5rem;
      padding: 15px;
      vertical-align: bottom;
    }
  }

  .m-reliability-table__title {
    background: #ef561f;
    color: $white;
    padding: 15px;
    font-weight: 700;
    margin: 0;
  }

  .m-reliability-table__border-right {
    border-right: 2px solid #d0d3dc;
  }

  .m-reliability-table__border-bottom {
    border-bottom: 2px solid #d0d3dc;
  }
}

@media only screen and (min-width: $desktop) {
  .m-reliability-table {
    padding: 0 70px;
    margin: 60px 0;
  }
}

.m-evidence-reference {
  padding: 60px 0;

  h2 {
    margin-bottom: 48px;
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.25;
  }

  .m-evidence-reference__button {
    display: block;
    margin: 0 12px 18px;
    padding: 18px;
    text-align: center;
    font-size: 1.25rem;
    text-decoration: none;
    font-weight: 700;
    border: 1.5px solid $midnight;

    &:hover,
    &:active {
      background: $inversehover;
      border-color: $midnight;
    }

    &::after {
      content: "PDF";
      color: #007495;
      background: #e7eff0;
      padding: 2px 8px;
      margin-left: 10px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-evidence-reference {
    .m-evidence-reference__button {
      margin: 0 12px;
    }
  }
}
/* Testemonial */
.m-testemonial-section {
  position: relative;
  text-align: center;
  padding-top: 36px;

  .m-testimonial-badge {
    width: 125px;
    position: relative;
    margin-top: -100px;
    margin-right: -60%;
  }

  img {
    width: 100%;
  }

  &.right {

    .m-testimonial-badge {
      margin-right: auto;
      margin-left: -60%;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-testemonial-section {
    padding-top: 50px;
  }
}

.m-testimonial-wrapper {
  .container {
    .col-12 {
      padding: 0;
    }
  }

  h2 {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .m-testemonial-section {
    max-width: 514px;
    margin-left: auto;
    margin-right: auto;
  }
}

.m-testemonial-section__content {
  padding: 32px 30px 0;
  background: $white;
  text-align: left;
  font-weight: 600;

  .text-bold {
    font-weight: 600;
  }

  .text-aqua {
    font-weight: 700;
  }

  .text-26 {
    font-size: 1.375rem;
    line-height: 1.25 !important;
  }

  .text-32 {
    line-height: 1.25 !important;
  }

  .text-light {
    font-size: 1.125rem !important;
    font-style: italic;
    color: #808080;
    font-weight: 600;
  }

  .m-standard-button {
    margin: 0;
    display: inline-block;
  }

  .m-quote-body {
    position: relative;

    .quot-left {
      position: absolute;
      left: -12px;
    }
  }
}

.m-serve-testimonial {
  .m-testemonial-section__content {
    padding: 32px 30px 0;

    .text-40 {
      line-height: 40px;
    }
  }
}

@media only screen and (min-width: 534px) {
  .m-serve-testimonial {
    .m-testemonial-section__content {
      padding: 32px 0 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-testemonial-section__content {
    padding: 32px 40px;

    .m-quote-body {
      .quot-left {
        left: -16px;
      }
    }

    .text-26 {
      font-size: 1.5rem;
    }

    .text-light {
      font-size: 1.25rem !important;
    }
  }

  .m-serve-testimonial {
    .m-testemonial-section__content {
      padding: 32px 0;

      .text-40 {
        line-height: 1.3;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-testimonial-wrapper {
    padding-left: 0;
    padding-right: 0;

    .container {
      padding: 0;
      max-width: 100%;
    }
  }

  .m-testemonial-section {
    text-align: left;

    .m-testimonial-badge {
      width: 181px;
      position: absolute;
      left: 80px;
      bottom: 10px;
    }

    &.right {
      text-align: right;

      .m-testimonial-badge {
        left: auto;
        right: 80px;
        bottom: 10px;
      }
    }

    img {
      width: auto;
    }
  }

  .m-testemonial-section__content {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    padding-right: 50px;

    &.right {
      left: 0;
      right: auto;
    }

    &.m-testemonial-section__content-wide {
      width: 55%;
      padding: 40px 64px;
      padding-right: 100px;
    }
  }

  .m-serve-testimonial {
    .m-testemonial-section__content {
      padding: 32px 40px;
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-testimonial-wrapper {
    .container {
      max-width: 1440px;
    }
  }

  .m-testemonial-section__content {
    &.m-testemonial-section__content-wide {
      padding-right: 130px;
    }
  }
}
/* Accordion content */
.m-accordion-content {
  padding-bottom: 60px;
  padding-top: 60px;

  .accordion {
    --bs-accordion-border-width: 0;
    counter-reset: number;
    margin-bottom: 0;

    button:focus {
      box-shadow: none;
    }
  }

  .accordion-item {
    border: 0 !important;
    padding-left: 40px;
  }

  .accordion-button {
    background: none;
    font-family: "Source Sans Pro", sans-serif;
    color: $midnight;
    font-size: 1.625rem;
    font-weight: 700;
    text-decoration: underline;
    counter-increment: number;
    position: relative;
    padding: 16px 15px 10px;
    padding-right: 0;

    &::before {
      content: counter(number) " ";
      position: absolute;
      left: -40px;
      background: #ccc;
      width: 45px;
      height: 45px;
      display: block;
      text-align: center;
      border-radius: 50%;
      padding: 6px 0;
    }

    &::after {
      display: none;
    }

    &[aria-expanded="true"] {
      text-decoration: none;

      &::before {
        background: $midnight;
        color: $white;
      }
    }
  }

  .accordion-body {
    color: $midnight;
    padding: 0 25px;
    padding-right: 0;

    p {
      margin-bottom: 0;
      font-size: 1.125rem;
      line-height: 1.3;
    }

    a {
      font-weight: 900;
    }
  }

  .m-accordion-image {
    display: block;
    padding: 0;
    padding-bottom: 38px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}


@media only screen and (min-width: $desktop) {
  .m-accordion-content {
    .accordion-button {
      padding: 16px 20px 10px;
      font-size: 2rem;

      &::before {
        padding: 2px 0;
      }
    }

    .accordion-item {
      padding-left: 50px;
    }

    .m-accordion-image {
      padding-right: 100px;
      padding-left: 0;
      padding-bottom: 0;
    }

    .accordion-body {
      padding: 0 25px;

      p {
        font-size: 1.375rem;
      }
    }

    .container {
      max-width: 100%;

      .accordion {
        padding-right: 60px;
      }
    }

    .m-accordion-btn {
      display: inline-block;
      transform: translateX(-40px);
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-accordion-content {
    padding-top: 100px;

    .container {
      max-width: 1440px;
    }
  }
}

.m-accordion-bullets {
  text-align: center;
  margin-top: 80px;

  a {
    color: transparent;
    background: $white;
    border: 2px solid $midnight;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 30px;

    &.active {
      background: $midnight;
    }
  }
}

.m-accordion-buttons a[data-index="prev"]::before {
  content: "";
  background: url("/images/glider-arrow-blue-prev.svg") no-repeat;
  width: 44px;
  height: 44px;
  background-size: cover;
  display: inline-block;
  margin-right: 40px;
}

.m-accordion-buttons a[data-index="next"]::before {
  content: "";
  background: url("/images/glider-arrow-blue-next.svg") no-repeat;
  width: 44px;
  height: 44px;
  background-size: cover;
  display: inline-block;
}

@media only screen and (min-width: $desktop) {
  .m-accordion-content {

    .accordion-item {
      padding-left: 0;
    }
  }
}

/* gradient carousel */
.m-gradient-section {
  color: $white;
  background: linear-gradient(180deg, #246885, $midnight);

  &.m-blue-grid {
    background: linear-gradient(180deg, #0081a6, #005973 50%);
  }

  &.m-gradient-section__lightblue {
    background: linear-gradient(180deg,#fff,#bde5ee,#0089b2,#131945 115%);
    color: $midnight;
    padding-top: 50px;

    h2 {
      margin-bottom: 12px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-gradient-section {
    &.m-gradient-section__lightblue {
      padding-top: 120px;
    }
  }
}

.glider-contain.multiple.partial {
  .glider-dot {
    border: 2px solid $white;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $white;
    }
  }

  .glider-slide {
    padding: 30px;
    align-content: flex-start;
    padding-left: 0;
  }

  .glider-prev {
    position: relative;
    color: $white;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    display: none;
    left: 0;
  }

  .glider-next {
    position: relative;
    color: $white;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    display: none;
  }

  img {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }
}

@media only screen and (min-width: $desktop) {
  .glider-contain.multiple.partial {
    .glider-prev {
      display: inline-block;
    }

    .glider-next {
      display: inline-block;
    }
  }
}

/* Image grid */
.m-image-grid {

  .m-image-grid__item {
    position: relative;
    cursor: default;

    > img {
      max-width: 100%;

      &.m-icon-image {
        max-width: 117px;
      }
    }

    > h3 {
      position: absolute;
      bottom: 20px;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      color: $white;
      display: none;
      font-weight: 800;
      width: 80%;
    }
  }

  .m-image-grid__item-overlay {
    padding: 50px;

    img {
      margin-bottom: 15px;
    }

    &.show {
      display: block;
    }

    &.topograph-bg {
      background-size: auto;
    }

    &.midnight-bg {
      color: #e6e6e6;
    }

    h3 {
      font-weight: 800;
      font-size: 2rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }
}

.m-image-grid-carousel {
  .container {
    max-width: 100%;
  }

  .glider-track {
    padding: 0;
  }

  .m-image-grid__item {
    > img {
      display: none;
    }
  }

  .m-image-grid__item-overlay {
    text-align: center;
    height: 100%;

    img {
      width: 100px;
    }
  }

  .m-carousel-controls {
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 12px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-ig-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 30px;
    border: 0;
  }

  .glider-ig-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 30px;
    border: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-image-grid-carousel {
    .m-image-grid__item {
      > img {
        display: block;
      }
    }

    .glider-dot {
      margin: 7px 22px;
    }
  }
}

.m-image-caption {
  font-size: 1rem;
  line-height: 20px;
  text-align: left;
  color: #808080;
  margin-top: 6px;
  margin-bottom: 0;

  a {
    color: #808080;
  }
}

@media only screen and (min-width: $desktop) {
  .m-image-gridfull {
    .container {
      max-width: 1440px;
    }
  }

  .m-image-grid {

    .m-image-grid__item {
      position: relative;

      > img {
        max-width: 100%;

        &.m-icon-image {
          max-width: 117px;
        }
      }

      > h3 {
        display: block;
      }

      &::after {
        background: linear-gradient(rgba(4, 30, 66, 0), rgba(4, 30, 66, .73));
        width: 100%;
        display: block;
        content: "";
        height: 80%;
        position: absolute;
        bottom: 0;
      }
    }

    .m-image-grid__item-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      display: none;
      z-index: 20;

      img {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-image-grid {
    .m-image-grid__item-overlay {
      padding: 40px;

      img {
        display: inline-block;
        max-width: 60px;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-image-grid {
    .m-image-grid__item-overlay {
      padding: 50px;

      img {
        max-width: 100px;
      }
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-image-gridfull {
    .container {
      max-width: 1440px;
    }
  }
}


/* Blog tiles */
.m-blog-tiles__tile {
  .m-blog-tiles__tile-container {
    padding: 4px;
    position: relative;

    img {
      width: 100%;
    }

    a {
      color: $midnight;
      text-decoration: none;

      &:visited,
      &:active {
        color: $midnight;
      }

      &:hover {
        color: $midnight;
        text-decoration: underline;
      }

      &::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  h3 {
    margin-top: 15px;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .m-blog-tiles__tile-subtext {
    color: #333;
  }
}

.m-blog-tiles {
  .glider-track {
    padding: 0;

    .m-blog-tiles__tile.glider-slide {
      padding: 0 16px;
    }
  }

  .m-carousel-controls {
    margin-top: 15px;
  }

  .glider-prev {
    content: "";
    background: url("/images/glider-arrow-blue-prev.svg") no-repeat;
    width: 44px;
    height: 44px;
    display: inline-block;
    position: relative;
    left: auto;
  }

  .glider-next {
    content: "";
    background: url("/images/glider-arrow-blue-next.svg") no-repeat;
    width: 44px;
    height: 44px;
    display: inline-block;
    position: relative;
    right: auto;
    margin-left: 40px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-tiles {
    .m-carousel-controls {
      display: none;
    }
  }
}

/* Tabbed content */
.m-tabbed-content {

  .nav-link {
    width: 50%;
    border-radius: 0;
    border-left: 1px solid $white !important;
    border-right: 1px solid $white !important;
    border-bottom: 3px solid $white !important;
    background: #f6f6f6;
    color: $midnight;
    font-size: 1.375rem;
    font-weight: 600;
    border-top: 0 !important;
    padding: 24px 16px !important;
    line-height: 1;

    &:last-child {
      width: 100%;
    }

    &.active {
      background: #00a82d;
      color: $white;
      border: 0;
      font-weight: 700;
    }

    &.green-tab {
      &.active,
      &:hover,
      &:active,
      &:focus {
        background: #00a82d;
      }
    }

    &.blue-tab {
      &.active,
      &:hover,
      &:active,
      &:focus {
        background: #0086d6 !important;
      }
    }

    &.orange-tab {
      &.active,
      &:hover,
      &:active,
      &:focus {
        background: #ef561f;
      }
    }

    &.purple-tab {
      &.active,
      &:hover,
      &:active,
      &:focus {
        background: #963cdb;
      }
    }

    &.red-tab {
      &.active,
      &:hover,
      &:active,
      &:focus {
        background: #d32f37;
      }
    }

    &:hover {
      background: #e44810;
      color: $white;
    }

    &:active {
      background: #ef561f;
      color: $white;
    }

    &:focus {
      background: #be3c0e;
      border: 2px solid $midnight;
      color: $white;
    }
  }

  .tab-content {
    .tab-contnet-badge {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 184px;
    }
  }

  .tab-pane {
    border-top: 35px solid transparent;
    max-width: 100vw;
    overflow: hidden;

    &.green-content {
      border-color: #00a82d;
    }

    &.blue-content {
      border-color: #0086d6;
    }

    &.orange-content {
      border-color: #fc4c02;
    }

    &.purple-content {
      border-color: #963cdb;
    }

    &.red-content {
      border-color: #d32f37;
    }

    &.no-border {
      border-top: none;
    }

    .tab-content-hero {
      width: 210%;
      transform: translateX(-35%);
      margin-bottom: 50px;
    }
  }
}

.m-tabbed-content__workshop-materials {
  .nav-link,
  .nav-link:hover {
    width: 100% !important;

    &:last-child {
      width: 100% !important;
    }
  }

  .m-book-subheading {
    margin: 0;
  }
}

.m-tab-count-2,
.m-tab-count-4 {
  button {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .m-tabbed-content {
    .nav-link {
      &:nth-child(odd) {
        border-left: 0 !important;
      }

      &:nth-child(even) {
        border-right: 0 !important;
      }

      &:last-child {
        border-left: 0 !important;
        border-right: 0 !important;
        width: 100%;
      }
    }
  }
}


@media only screen and (min-width: $desktop) {

  .m-tabbed-content {
    .tab-pane {
      .tab-content-hero {
        width: 100%;
        transform: none;
        margin-bottom: 0;
      }
    }

    .nav-tabs.fixed {
      position: fixed;
      z-index: 1;
      width: 100%;

      .nav-link.active {
        &::before {
          content: "";
          position: absolute;
          top: 100%;
          left: -150%;
          width: 300%;
          height: 35px;
        }

        &.green-tab {
          &::before {
            background: #00a82d;
          }
        }

        &.blue-tab {
          &::before {
            background: #0086d6;
          }
        }

        &.orange-tab {
          &::before {
            background: #ef561f;
          }
        }

        &.purple-tab {
          &::before {
            background: #963cdb;
          }
        }

        &.red-tab {
          &::before {
            background: #d32f37;
          }
        }
      }
    }

    .tab-content {
      padding-top: 0;
    }

    .tab-content-fixed {
      margin-top: 69px;
    }

    .nav-link,
    .nav-link:hover {
      width: 20%;
      border-bottom: 0 !important;

      &:first-child {
        border-left: 0 !important;
      }

      &:last-child {
        width: 20%;
        border-right: 0 !important;
      }
    }
  }

  .m-tabbed-content__workshop-materials {
    .nav-link,
    .nav-link:hover {
      width: 50% !important;

      &:last-child {
        width: 50% !important;
      }
    }

    .m-book-subheading {
      margin-top: 60px;
    }
  }

  .m-tab-count-4 {
    .nav-link {
      width: 25% !important;
    }
  }

  .m-tab-count-2 {
    .nav-link {
      width: 50% !important;
    }
  }
}


/* checkmark list */
.m-checkmark-list {
  margin-top: 50px;
  list-style: none;

  li {
    margin-bottom: 2rem;
    position: relative;

    &::before {
      position: absolute;
      left: -46px;
      content: "";
      background: url("/images/practices/checkmark-green.svg") no-repeat;
      width: 32px;
      height: 32px;
      display: block;
      top: 3px;
    }
  }

  &.m-checkmark-list--blue {
    li {
      &::before {
        background: url("/images/practices/checkmark-blue.svg") no-repeat;
      }
    }
  }

  &.m-checkmark-list--orange {
    li {
      &::before {
        background: url("/images/practices/checkmark-orange.svg") no-repeat;
      }
    }
  }

  &.m-checkmark-list--purple {
    li {
      &::before {
        background: url("/images/practices/checkmark-purple.svg") no-repeat;
      }
    }
  }

  &.m-checkmark-list--red {
    li {
      &::before {
        background: url("/images/practices/checkmark-red.svg") no-repeat;
      }
    }
  }
}
/* Newsletter */
.m-newsletter-content {
  padding: 45px 55px 30px;
  position: relative;

  img {
    height: 35px;
    margin-bottom: 23px;
    max-width: 100%;
  }
}

@media only screen and (min-width: $desktop) {
  .m-newsletter-content {
    padding: 30px;
  }
}

.m-newsletter-button {
  padding: 50px 70px;
  text-align: center;
  position: relative;

  .m-standard-button {
    display: inline-block;
    padding: 10px 58px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-newsletter-content {
    padding: 50px 0px 50px 65px;
    text-align: left;
    margin-bottom: 0;
  }

  .m-newsletter-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 50px 40px;
  }
}

@media only screen and (min-width: $large-desktop) {

  .m-newsletter-button {
    padding: 50px 70px;
  }
}

/* Authors */
.m-author-gradient {
  background: linear-gradient(180deg, #131945 30%, #0089b2);
  position: relative;

  &::before {
    background: linear-gradient(180deg, rgba(0, 137, 178, .25), rgba(9, 39, 79, .7));
    position: absolute;
    content: "";
    width: 100%;
    height: 25%;
    bottom: 0;
  }

  .container {
    position: relative;
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 2.125rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .m-standard-button {
    display: inline-block;
    margin: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: $desktop) {
  .m-author-gradient {

    &::before {
      height: 40%;
    }

    h2 {
      font-size: 2.5rem;
    }

    .m-standard-button {
      left: 0;
      transform: none;
    }
  }
}

.m-author-book {
  text-align: center;

  img {
    max-width: 100%;
    height: 420px;
    margin-top: 32px;
  }
}

.m-author-book__overlay {
  position: absolute;
  right: 0;
  background: $white;
  color: $midnight;
  padding: 16px 30px;
  font-size: 34px;
  max-width: 280px;
  line-height: 1.1;
  font-family: "Source Serif Pro";
  font-weight: 700;
  bottom: 22px;
}

@media only screen and (min-width: $desktop) {
  .m-author-book {
    text-align: initial;

    img {
      height: auto;
      margin-top: 0;
    }
  }

  .m-author-book__overlay {
    bottom: 79px;
    font-size: 40px;
  }
}

.m-author-timeline {
  background: url("/images/authors/author-timeline-background.jpg");
  background-position: top;
  background-size: cover;
  padding: 0 0 60px;
}

.m-vertical-timeline__lastrow {
  text-align: left;
  background: $midnight;
  margin-top: 100px;
  padding: 60px 20px;
  position: relative;
  color: $white;

  h3 {
    font-weight: 700;
    font-family: "Source Serif Pro", serif;
    margin-bottom: 16px;
    font-size: 2.5rem;
    text-align: center;
  }

  p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 32px;
  }
}

.m-verticaltimeline__content-icon-today {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  img {
    width: 88px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-vertical-timeline__row {
    padding: 30px 0;
    align-items: center;
  }

  .m-vertical-timeline__firstrow {
    padding-top: 20px;
  }

  .m-vertical-timeline__lastrow {
    text-align: center;
    padding: 60px 180px;

    p {
      font-size: 1.5rem;
    }
  }
}

.m-vertical-timeline__image {
  position: relative;
  order: 2;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  padding: 0 23px;

  &::after {
    content: "";
    width: 2.5px;
    height: 150%;
    background: $midnight;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  img {
    max-width: 100%;
  }
}

.m-vertical-timeline__content {
  position: relative;

  .m-vertical-timeline__content-box {
    padding: 30px;
    background: $white;
    font-size: 1.25rem;
    line-height: 1.4;
    position: relative;
    padding-bottom: 60px;

    h3 {
      font-family: "Source Serif Pro", serif;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .m-vertical-timeline__content-icon {
      position: absolute;
      width: 88px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      z-index: 1;
    }

    .blue {
      display: none;
    }
  }

  &.active {

    &::after {
      border-right: 2.5px solid $midnight;
    }

    .m-vertical-timeline__content-box {

      .m-vertical-timeline__content-icon {
        &::after {
          border-top: 2.5px solid $midnight !important;
        }
      }

      .grey {
        display: none;
      }

      .blue {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .m-vertical-timeline__content {
    position: relative;

    &::after {
      content: "";
      border-right: 2.5px solid #e6e6e6;
      height: 180%;
      display: block;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translate(-1px, 100%);
    }
  }

  .m-vertical-timeline__lastrow {
    .m-verticaltimeline__content-icon-today {
      z-index: 1;
    }

    &::after {
      content: "";
      border-right: 2.5px solid $midnight;
      height: 100%;
      display: block;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translate(-1px, -100%);
    }
  }

  .m-vertical-timeline__image {
    img {
      position: relative;
      z-index: 1;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-vertical-timeline__content {
    position: relative;

    &::after {
      content: "";
      border-right: 2.5px solid #e6e6e6;
      height: calc(100% + 60px);
      display: block;
      top: 69px;
      position: absolute;
      right: 0;
      left: auto;
      transform: translateX(1px);
    }

    .m-vertical-timeline__content-box {
      margin-right: 100px;
      padding: 40px 41px 40px 40px;
      font-size: 1.5rem;
      line-height: 1.4;
      position: relative;
      padding-bottom: 40px;

      .m-vertical-timeline__content-icon {
        position: absolute;
        width: 88px;
        top: 25px;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateX(70%);
      }

      .blue {
        display: none;
      }
    }

    &.active {

      &::after {
        border-right: 2.5px solid $midnight;
      }

      .m-vertical-timeline__content-box {

        .m-vertical-timeline__content-icon {
          &::after {
            border-top: 2.5px solid $midnight !important;
          }
        }

        .grey {
          display: none;
        }

        .blue {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-vertical-timeline__image {
    order: 2;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 12px;

    &::after {
      content: none;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-vertical-timeline__row {
    .m-vertical-timeline__content {
      .m-vertical-timeline__content-icon {
        &::after {
          content: "";
          border-top: 2.5px solid #e6e6e6;
          position: absolute;
          width: 100%;
          right: -61px;
          top: 50%;
          z-index: -1;
        }
      }
    }
  }

  .m-vertical-timeline__row-alternate {
    .m-vertical-timeline__content {

      &::after {
        right: inherit;
        left: 0;
        transform: translateX(-1px);
      }

      .m-vertical-timeline__content-box {
        margin-right: 0;
        margin-left: 100px;

        .m-vertical-timeline__content-icon {
          position: absolute;
          width: 88px;
          top: 25px;
          right: auto;
          left: 0;
          transform: translateX(-70%);

          &::after {
            content: "";
            border-top: 2.5px solid #e6e6e6;
            position: absolute;
            width: 100%;
            right: inherit;
            left: -61px;
            top: 50%;
            z-index: -1;
          }
        }
      }
    }

    .m-vertical-timeline__image {
      order: inherit;
      padding-right: inherit;
    }
  }
}

.m-author-hero__container {
  padding: 0;
}

.m-author-hero {
  position: relative;

  .m-author-hero__title {
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px 6px;
    background: $white;
    color: $midnight;
    z-index: 1;
    width: 100%;

    h1 {
      margin-bottom: 0;
      font-size: 2.5rem;
      font-weight: 900;
    }
  }

  .m-linkedin-color {
    width: 28px;
    height: auto;
    margin-left: 30px;
  }

  .m-author-hero__image {
    position: relative;
    width: 100%;
  }
}

.m-authors-hero {
  display: none;

  .m-author-hero__image {
    width: 100% !important;
    transform: translateY(50px);
  }

  h2 {
    font-size: 2.25rem;
  }

  p {
    padding: 0 15px;
  }

  &.m-authors-hero__content {
    display: block;
    margin: 0 -12px;
    background: $midnight;

    .m-author-hero__title {
      width: 90%;
      transform: translateX(5%);
      justify-content: center;
    }
  }
}

.m-authors-bio {
  p:last-child {
    margin-top: 2rem;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 1199px) {
  .m-author-hero.m-authors-hero.m-authors-hero__content {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: -100%;
      display: block;
      background: $midnight;
    }

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      right: -100%;
      display: block;
      background: $midnight;
      top: 0;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-author-hero__container {
    padding: 0;
  }

  .m-author-hero {
    position: relative;

    .m-author-hero__title {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 30px 50px 6px;
      background: $white;
      color: $midnight;
      width: auto;

      h1 {
        margin-bottom: 0;
        font-size: 3.125rem;
      }
    }

    .m-linkedin-color {
      width: 28px;
      height: auto;
      margin-left: 30px;
    }

    .m-author-hero__image {
      position: relative;
      left: calc(100% + 60px);
      transform: translateX(-100%);
      width: auto;
    }
  }

  .m-author-landinghero {
    padding-top: 70px;
    padding-left: 0;
    padding-right: 0;

    .text-24 {
      margin-bottom: 0;
    }

    h1 {
      font-size: 3.125rem;
      margin-bottom: 1rem;
    }
  }

  .m-authors-hero {
    display: block;
    overflow: hidden;

    .m-author-hero__image {
      transform: translate(-100%, 50px);
    }

    &.m-authors-hero__content {
      display: none;
    }
  }

  .m-authors-bio {
    p:last-child {
      margin-bottom: 1rem;
    }
  }
}

.m-author-bio {
  padding: 0 30px;
  margin-bottom: 40px;

  h2 {
    color: #0081a6;
    font-family: "Source Serif Pro";
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 30px;
    padding-top: 1rem;
  }

  .text-36.text-serif {
    margin: 32px 0 16px;
    line-height: 1.25;
    font-weight: 600;
    font-size: 2rem;

    .quot-left {
      position: absolute;
      transform: translateX(-16px);
    }

    .quot-right {
      position: absolute;
    }
  }

  .m-author-bio__returnlink {
    font-weight: 700;
    color: $midnight;
    margin-top: 2rem;
    display: block;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-authors-hero {
    h2 {
      font-size: 2.5rem;
      line-height: 1;
      margin: 0;
    }
  }

  .m-author-bio {
    padding: 0 328px;

    .text-36.text-serif {
      font-size: 2.25rem;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-author-bio {
    padding: 0 328px;
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-author-bio {
    padding: 0 385px;
  }
}


.m-authors-content {
  padding-top: 0;

  .m-authors-hero__content {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-authors-content {
    padding-top: 94px;
    padding-bottom: 66px;

    .m-authors-hero__content {
      margin-bottom: 0;
    }
  }
}

.m-author-landinghero {
  text-align: center;
  padding-left: 11px;
  padding-right: 11px;

  h1 {
    font-size: 2.625rem;
  }
}
/* books */
.m-books-hero {
  padding-top: 40px;

  h1 {
    margin-bottom: 2rem;
  }
}

.m-book-carousel {
  .glider-slide {
    padding: 30px 20px 159px;
    position: relative;
    text-align: center;

    .m-book-carousel__detail {
      display: block !important;
    }

    &::before {
      background: radial-gradient(#0089b2 0%, rgba(4, 30, 66, 0) 75%);
      width: 280px;
      height: 280px;
      content: "";
      position: absolute;
      /* z-index: -1; */
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      transform: scale(1.1);
      transition: all .3s ease;
    }

    a {
      display: block;
    }
  }

  .glider-prev {
    color: $white;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    left: 5px;
  }

  .glider-next {
    color: $white;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    right: 5px;
  }

  .dots {
    transform: translateY(-50px);
    display: none;
  }

  .m-book-carousel__detail {
    position: absolute;
    display: none;
    left: 0;
    padding: 0 50px;
    width: 100%;

    .m-book-carousel__detail-title {
      font-family: "Source Serif Pro", serif;
      font-weight: 600;
      font-style: italic;
      margin-top: 50px;
      margin-bottom: 0;
      font-size: 24px;
      line-height: 1.25;
    }

    .m-book-carousel-detail-abstract {
      color: #25cae1;
      font-size: 1rem;
      line-height: 1.25;
      padding: 0 11px;
    }
  }
}

.m-book-offset {
  transform: translateX(-10px);
}

@media only screen and (min-width: $desktop) {
  .m-books-hero {
    padding-top: 70px;
  }

  .m-book-carousel {
    .glider.draggable {
      padding: 0 20px;
    }

    .glider-slide {
      .m-book-carousel__detail {
        display: none !important;
        padding: 0;
      }

      &::before {
        content: none;
      }

      img {
        transform: scale(1);
      }

      &:hover,
      &.first-load {
        width: 10% !important;
        cursor: pointer;

        .m-book-carousel__detail {
          display: block !important;
        }

        &::before {
          background: radial-gradient(#0089b2 0%, rgba(4, 30, 66, 0) 75%);
          width: 280px;
          height: 280px;
          content: "";
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        }

        img {
          transform: scale(1.1);
        }
      }
    }

    .glider-contain::after {
      position: absolute;
      width: 150px;
      height: 100%;
      display: block;
      background: linear-gradient(90deg, transparent, $midnight);
      right: 0;
      top: 0;
      content: "";
    }

    .glider-prev {
      display: block;
      left: -35px;
    }

    .glider-next {
      display: block;
      right: -35px;
    }

    .dots {
      display: flex;
      margin-top: 50px;

      .glider-dot {
        background: transparent;
        border: 2px solid $white;
        width: 16px;
        height: 16px;
        margin: 0 12px;

        &.active {
          background: $white;
        }
      }
    }
  }
}


.m-book-listing {
  overflow: hidden;
}

.m-book-subheading {
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  font-family: "Source Serif Pro", serif;
  transform: translateY(20px);
  margin-top: 40px;
  padding: 0;
}

@media only screen and (min-width: $desktop) {
  .m-book-subheading {
    font-size: 3.125rem;
    transform: translateY(40px);
    margin-top: 80px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-book-subheading {
    padding-bottom: 0;
  }
}

.m-book-detail {
  margin-top: 22px;
  align-items: center;
}

.m-book-detail__image {
  padding: 15px;
  position: relative;
  text-align: center;

  &::before {
    content: "";
    background: #f2f2f2;
    height: 100%;
    width: 200%;
    display: block;
    position: absolute;
    z-index: -1;
    right: 50%;
  }

  img {
    max-width: 100%;
  }

  .m-book-detail__overlay {
    position: absolute;
    background: #fff;
    color: #041e42;
    padding: 24px 30px 24px 0;
    font-size: 40px;
    max-width: 280px;
    line-height: 1.1;
    font-family: "Source Serif Pro";
    font-weight: 700;
    bottom: 0;
    text-align: right;

    &::before {
      height: 100%;
      width: 200%;
      content: "";
      background: $white;
      display: block;
      position: absolute;
      left: -100%;
      top: 0;
      z-index: -1;
      box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
    }
  }
}

@media only screen and (min-width: $large-desktop) {

  .m-book-detail {
    margin-top: 65px;
    align-items: center;
  }

  .m-book-detail__image {
    padding: 15px;
    position: relative;
    text-align: initial;

    &::before {
      content: "";
      background: #f2f2f2;
      height: 100%;
      width: 200%;
      display: block;
      position: absolute;
      z-index: -1;
      right: 50%;
    }
  }
}

.m-book-detail__content {
  font-size: 1.25rem;
  line-height: 1.4;
  padding: 30px 0;
  margin-top: 40px;

  h2 {
    margin: 0;
    font-size: 2rem;
    line-height: 1.25;
  }

  p {
    margin-bottom: 1.25rem;
    margin-top: 1.5rem;

    &.m-book-detail__abstract {
      color: #0081a6;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 1.375rem;
    }
  }

  .m-standard-button {
    display: inline-block;
  }

  .m-book-links {
    .m-standard-button {
      margin: 0;
      display: block;
      font-size: 1.25rem;
      text-align: center;
      padding: 10px 42px 14px;

      &::after {
        content: "";
        background: url("/images/chevron-down-white.svg") no-repeat;
        width: 15px;
        height: 25px;
        display: inline-block;
        transform: translateY(50%);
        margin-left: 12px;
      }
    }

    .m-book-links__selection {
      background: #e6e6e6;
      flex-wrap: wrap;
      box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
      display: none;
      text-align: center;

      a {
        display: block;
        width: 100%;
        padding: 7.5px;
        border: 1px solid rgba(4, 30, 66, .5);
      }
    }

    &.active {
      .m-book-links__selection {
        display: flex;
      }

      .m-standard-button {

        &::after {
          content: "";
          background: url("/images/chevron-up-white.svg") no-repeat;
        }
      }
    }
  }
}


@media only screen and (min-width: $desktop) {
  .m-book-detail__content {
    font-size: 1.5rem;
    padding: 30px 50px;
    margin-top: 0;

    h2 {
      font-size: 2.5rem;
    }

    p {
      margin-bottom: 1.5rem;

      &.m-book-detail__abstract {
        font-size: 1.5rem;
      }
    }

    .m-book-links {
      .m-standard-button {
        display: inline-block;
        text-align: left;
      }

      .m-book-links__selection {
        margin: 4px 0;
        position: absolute;
        width: 382px;
        z-index: 1;

        a {
          width: 50%;
        }
      }
    }
  }
}



@media only screen and (min-width: $large-desktop) {
  .m-book-detail__offset {
    .m-book-detail__image {
      order: 2;

      &::before {
        right: auto;
        left: 50%;
      }
    }

    .m-book-detail__content {
      order: 1;
    }
  }
}

/* research library */
.m-research-searchbox {
  background: $white;
  padding: 30px 10px 0;
  margin: 40px 10px 0;

  h2 {
    font-size: 2.5rem;
    line-height: 50px;
    text-align: center;
    margin-bottom: 32px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 1.25rem;
  }

  input {
    padding: 11px;
    width: 100%;
    margin-bottom: 20px;
  }

  button {
    margin: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 28px;
  }

  p {
    margin-top: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: $tablet) {
  .m-research-searchbox {
    > .col-12 {
      padding: 0;
    }
  }
}

.m-research-title {
  display: none;
}

@media only screen and (min-width: $desktop) {
  .m-research-searchbox {
    padding: 55px 100px 50px;

    h3 {
      font-size: 2.25rem;
      margin-bottom: 36px;
    }

    p {
      font-size: 1.5rem;
    }

    input {
      width: 66%;
    }

    button {
      left: 0;
      transform: none;
      margin-left: 32px;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-research-searchbox {
    padding: 50px 150px;

    input {
      width: 70%;
    }
  }

  .m-research-title {
    display: block;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-research-searchbox {
    padding: 50px 180px;

    input {
      width: 74%;
    }
  }
}

.m-research-table__container {
  overflow: hidden;
}

.m-research-table {
  padding: 30px 30px 120px;

  .m-research-table__header {
    border-bottom: 1px solid #e6e6e6;
    font-weight: 700;
    padding: 10px 0;
    position: relative;

    &::before {
      content: "";
      width: 50%;
      height: 100%;
      bottom: -1px;
      left: 0;
      transform: translateX(-100%);
      position: absolute;
      border-bottom: 1px solid #e6e6e6;
    }

    &::after {
      content: "";
      width: 50%;
      height: 100%;
      bottom: -1px;
      right: 0;
      transform: translateX(100%);
      position: absolute;
      border-bottom: 1px solid #e6e6e6;
    }


    .col-12 {
      padding: 10px 30px;
      font-size: 1.25rem;
    }
  }

  .m-research-table__item {
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 0;

    .col-12 {
      padding: 10px 30px;
    }
  }

  .m-research-table__item-industry {
    color: #007495;
    background: #e7eff0;
    padding: 3px;
  }

  .m-research-table__item-role {
    color: #bd3200;
    background: #fcddd2;
    padding: 3px;
  }
}

.m-research-table__item {
  .col-xl-5 {
    a {
      font-size: 1.5rem;
      text-decoration: none;
      line-height: 1.2;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .col-xl-3,
  .col-xl-4 {
    font-size: 1.25rem;
  }
}

.m-research-detail {
  padding: 0 30px;

  .m-standard-button {
    margin-left: 0;
    margin-bottom: 20px;
    display: inline-block;
  }

  h3 {
    color: #0081a6;
    font-family: "Source Serif Pro";
    font-size: 2.125rem;
    font-weight: 700;
    margin-bottom: 30px;
  }

  p {
    font-size: 1.5rem;
    margin: 0 0 30px;
    line-height: 1.4;
  }
}

.m-abstract-researchers {
  p {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 2rem;
    }
  }
}

.m-research-pagination {
  margin-top: 64px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.page-number, .page-arrow, .ellipsis {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
}

.page-number:hover, .page-arrow:hover {
  background-color: #f0f0f0;
}

.page-number.active {
  background-color: $midnight;
  color: $white;
  font-weight: 700;
  font-size: 1.25rem;
}

.page-arrow {
  font-weight: 700;
  width: 30px;
  height: 30px;

  &::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: url("/images/blue-circle-arrow.svg") no-repeat;
    background-size: cover;
  }

  &.prev {
    &::before {
      transform: rotate(180deg);
    }
  }
}

@media only screen and (min-width: 414px) {
  .page-number, .page-arrow, .ellipsis {
    margin: 0 8px;
  }

  .page-arrow {
    width: 50px;
    height: 50px;

    &::before {
      width: 50px;
      height: 50px;
    }
  }
}

.research-no-results {
  font-size: 1.875rem;
  text-align: center;
  margin-top: 50px;
}

.page-arrow.disabled {
  opacity: 0.5;
  cursor: default;
}

.ellipsis {
  cursor: default;
}

.m-research-dropdown ul li {
  cursor: pointer;
  padding: 5px;
  font-weight: 400;
}

.m-research-dropdown {
  background: $white;

  input[type="checkbox"] {
    width: 22px;
    height: 22px;
    transform: translateY(5px);
    margin-right: 10px;
  }
}

.m-research-dropdown ul li:hover {
  background-color: #f0f0f0;
}

@media only screen and (min-width: $large-desktop) {
  .m-research-detail {
    padding: 0 260px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-research-detail {
    padding: 0 320px;
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-research-detail {
    padding: 0 360px;
  }
}

.m-research-dropdowntoggle {
  position: relative;
  cursor: pointer;

  .m-research-dropdowntoggle__text {
    font-size: 1.25rem;
  }

  &::after {
    position: relative;
    content: "";
    background: url("/images/chevron-down.svg") no-repeat;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 5px;
  }

  .m-research-dropdown {
    position: absolute;
    background: $white;
    box-shadow: 2px 2px 6px #e6e6e6;
    padding: 10px;
    left: 0;
    top: 45px;
    display: none;
    width: 200%;
    z-index: 1;

    &.m-roles-dropdown {
      left: auto;
      right: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    button {
      text-align: right;
      display: block;
      width: 100%;
      background: none;
      border: none;
      font-weight: 700;
    }
  }

  &.active {
    .m-research-dropdown {
      display: block;
    }

    &::after {
      background: url("/images/chevron-up.svg") no-repeat;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-research-dropdowntoggle {
    padding: 10px 20px;

    .m-research-dropdown {
      left: 30px;
      width: 100%;

      &.m-roles-dropdown {
        left: 30px;
        right: auto;
      }
    }
  }
}

.m-research-items {
  min-height: 200px;
}

.m-research-table__item-industry__header {
  color: #007495;
  background: #e7eff0;
  padding: 3px;
  margin: 10px;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.3;
}

.m-research-table__item-role__header {
  color: #bd3200;
  background: #fcddd2;
  padding: 3px;
  margin: 10px;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.3;
}

.m-research-detail__return {
  font-size: 1.5rem;
}
/* workshops */
.m-workshop-grid__content {
  text-align: center;
  padding: 20px 0;
  font-size: 1.25rem;
  line-height: 1.25;

  img {
    height: 227px;
    width: auto;
    max-width: 100%;
  }

  .m-workshop-grid__content-title {
    font-size: 2rem;
    font-family: "Source Serif Pro", serif;
    font-weight: 700;
    margin-top: 16px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-workshop-grid__content {
    padding: 40px 51px;
    font-size: 1.5rem;

    img {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-workshop-grid__content {
    padding: 40px 60px;
  }
}


.m-workshop-mountain {
  padding: 0;

  img {
    max-width: 100%;
    width: 100%;
  }
}

.m-workshop-modules__background {
  background: url("/images/workshops/workshop-topography-background.jpg");
  background-position: center;
  padding-bottom: 40px;
  padding-top: 70px;

  h2 {
    margin-bottom: 20px;
  }
}

.m-workshop-paragraph-padding {
  padding-bottom: 40px !important;
}

@media only screen and (min-width: $desktop) {
  .m-workshop-modules__background {
    padding-bottom: 60px;
    padding-top: 120px;

    h2 {
      margin-bottom: 12px;
    }
  }

  .m-workshop-paragraph-padding {
    padding-bottom: 60px !important;
  }
}

.m-workshop-modules {
  .m-workshop-modules-subhead {
    font-size: 1.75rem;
    line-height: 36px;
    margin: 60px 32px 32px;
    text-align: center;
    font-weight: 700;
  }

  .m-workshop-module__item {
    position: relative;
    margin-bottom: 32px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .08);

    .m-workshop-module__eyebrow {
      background: #0089b2;
      font-size: 1.875rem;
      line-height: 40px;
      padding: 12px 40px;
      color: $white;
      font-weight: 700;
      border: 1px solid #0089b2;
      border-bottom: 0;
    }

    .m-workshop-module__eyebrow-icon {
      position: absolute;
      right: 20px;
      top: -19px;

      img {
        width: 92px;
      }
    }

    .m-workshop-module__content {
      border: 1px solid #b3b3b3;
      border-top: 0;
      padding: 29px;
      background: $white;
    }

    .m-workshop-module__title {
      margin-bottom: 1rem;
      font-size: 2.125rem;
      font-weight: 700;
      font-family: "Source Serif Pro", serif;
      line-height: 1.2;
      padding-right: 40px;
    }

    .m-workshop-module__body {
      font-size: 1.25rem;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-workshop-modules {
    padding: 0 116px;

    .m-workshop-modules-subhead {
      font-size: 2rem;
      line-height: 40px;
    }

    .m-workshop-module__item {

      .m-workshop-module__eyebrow {
        font-size: 2rem;
      }

      .m-workshop-module__eyebrow-icon {
        right: 40px;
        top: -10px;

        img {
          width: 120px;
        }
      }

      .m-workshop-module__content {
        padding: 40px;
      }

      .m-workshop-module__title {
        margin-bottom: 1rem;
        font-size: 2.5rem;
        font-weight: 700;
        font-family: "Source Serif Pro", serif;
        padding-right: 0;
      }

      .m-workshop-module__body {
        font-size: 1.5rem;
      }
    }
  }
}

.m-workshop-checklist {
  padding-left: 30px;

  p {
    position: relative;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 2rem;

    &::before {
      position: absolute;
      width: 26px;
      height: 26px;
      display: block;
      content: "";
      background: url("/images/workshops//workshop-checkmark.svg") no-repeat;
      left: -41px;
      top: 7px;
    }
  }

  a {
    font-weight: 900;
  }
}

.m-workshop-checlist__image {
  position: relative;
  padding: 24px 0;
  text-align: center;

  img {
    position: relative;
    max-width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: $desktop) {
  .m-workshop-checlist__image {
    padding: 0;

    img {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-workshop-checlist__image {
    img {
      transform: translateY(-55%);
    }
  }
}

.m-workshop-checklist__button {
  margin-top: 34px;

  .m-standard-button {
    font-size: 1.125rem;
    padding: 16px 58px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-workshop-checklist__button {
    margin-top: 94px;

    .m-standard-button {
      font-size: 1.25rem;
    }
  }
}

.m-workshop-materials {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;

  h2 {
    margin-bottom: 50px;
    font-size: 2.375rem;
    font-weight: 700;
    text-align: center;
  }

  .m-carousel-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }

  .glider-dots {
    margin: 0;

    .glider-dot {
      margin: 7px 9px;
      background: transparent;
      border: 2px solid $white;
      width: 20px;
      height: 20px;

      &.active {
        background: $white;
      }
    }
  }

  .glider-prev {
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    position: relative;
    left: 0;
  }

  .glider-next {
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    position: relative;
    right: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-workshop-materials {

    h2 {
      font-size: 3.125rem;
    }

    .glider-dots {
      .glider-dot {
        margin: 7px 22px;
      }
    }

    .glider-prev {
      left: -60px;
    }

    .glider-next {
      right: -60px;
    }
  }
}

.m-workshop-materials__gradient {
  background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945 120%);
}

.m-workshop-materisl__slide {
  margin: 0 16px;
  margin-bottom: 20px;
}

.m-workshop-materials__content {
  padding: 60px 22px 30px;
  background: $white;
  height: 100%;
  font-size: 1.5rem;
  color: $midnight;
  line-height: 1.25;
  font-weight: 700;
  text-align: center;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);

  img {
    width: 88px;
    height: 88px;
    margin-bottom: 30px;
    display: block;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}

/* assessment pages */
.m-lpi-carousel {
  margin-top: 1rem;

  .glider {
    display: none;
  }

  .m-carousel-controls {
    display: none;
    justify-content: center;
    margin-top: 70px;
    align-items: center;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 40px;
  }

  .glider-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 40px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-lpi-carousel {
    .m-carousel-controls {
      display: flex;
    }

    .glider {
      display: block;
    }
  }
}

.m-lpi-carousel__slide-content {
  margin: 0 150px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .08);
}

.m-lpi-carousel__slide-banner {
  background: #0081a6;
  color: $white;
  padding: 32px;
  border: 1px solid #0081a6;
  border-bottom: 0;

  .m-lpi-carousel__slide-title {
    font-size: 1.875rem;
    font-family: "Source Serif Pro", serif;
    font-weight: 700;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}

.m-lpi-carousel__slide-image {
  text-align: center;
  background: $white;
  padding: 40px;
}

.m-sample-report {
  text-align: center;

  .m-standard-button {
    display: inline-block;
    margin-top: 50px;
  }
}

.m-lpi-comparison {
  .row {
    align-items: center;
  }

  .m-lpi-comparison__content {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    font-family: "Source Serif Pro", serif;
    padding: 0 10px;
  }

  .m-lpi-comparison__image {
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-lpi-comparison {
    .m-lpi-comparison__content {
      font-size: 2rem;
      padding: 0 80px;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-lpi-comparison {

    .m-lpi-comparison__image {
      margin-bottom: 0;
    }
  }
}

.m-assessment-grid {
  padding: 100px 0 20px;

  .container {
    max-width: 100%;
    padding: 0;

    .row {
      margin: 0;
    }
  }

  h2 {
    margin-bottom: 40px;
  }

  .m-image-grid__item {
    padding: 0;

    &.col-lg-4 {
      .m-image-grid__item-overlay {
        padding: 35px 30px !important;
        display: block;
        background: #f2f2f2;
        color: #041e42;
      }
    }

    &.col-lg-4:first-child {
      border-left: 0;
    }

    &.col-lg-4:last-child {
      border-right: 0;
    }

    &::after {
      display: none;
    }

    > img {
      margin-bottom: 30px;
    }

    > h3 {
      position: relative;
      left: auto;
      transform: none;
      font-weight: 800;
    }
  }

  .m-image-grid__item-overlay.show {
    padding: 45px;
    background: #f2f2f2;
    color: $midnight;

    h3 {
      font-size: 2rem;
      font-weight: 800;
    }

    p {
      line-height: 1.3;
    }

    img {
      max-width: 117px !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .m-assessment-grid {
    .m-image-grid__item-overlay {
      padding: 45px;
      background: #f2f2f2;
      color: $midnight;
      display: block !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-assessment-grid {
    h2 {
      margin-bottom: 40px;
    }

    .m-image-grid__item {
      padding: 70px 90px 90px;
      border: 2px solid $white;

      &.col-lg-6:nth-child(odd) {
        border-left: 0;
      }

      &.col-lg-6:nth-child(even) {
        border-right: 0;
      }

      &.col-lg-4 {
        padding: 70px;

        .m-image-grid__item-overlay .show {
          padding: 35px 80px !important;
        }
      }

      &.col-lg-4:first-child {
        border-left: 0;
      }

      &.col-lg-4:last-child {
        border-right: 0;
      }

      &::after {
        display: none;
      }

      > img {
        margin-bottom: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }

      > h3 {
        position: relative;
        font-weight: 800;
        width: 100%;
      }
    }

    .m-image-grid__item-overlay {
      display: none !important;

      &.show {
        padding: 45px;
        background: #f2f2f2;
        color: $midnight;
        display: block !important;

        h3 {
          font-size: 2rem;
          font-weight: 800;
        }

        p {
          font-size: 1.25rem;
          line-height: 1.3;
        }

        img {
          max-width: 117px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-assessment-grid {
    h2 {
      margin-bottom: 60px;
    }
  }
}


@media only screen and (min-width: $xx-large-desktop) {
  .m-assessment-grid {
    margin-left: -2px;
    margin-right: -2px;

    .container {
      max-width: 1440px;
      padding: 0 12px;

      .row {
        margin: 0 -12px;
      }
    }

    h2 {
      margin-bottom: 80px;
    }
  }
}


.m-lpi-learnmoregrid {
  .glider-track {
    padding-bottom: 20px;
  }

  &.m-practices-arrow {
    &::before {
      bottom: -55%;
    }
  }

  .m-lpi-learnmoregrid__content {
    text-align: center;
    background: $white;
    padding: 35px 25px 60px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, .08);
    margin: 0 15px;

    img {
      margin-bottom: 20px;
      width: 120px;
      height: 120px;
    }

    h3 {
      font-size: 2rem;
      font-weight: 700;
      font-family: "Source Serif Pro", serif;
    }

    .m-lpi-learnmoregrid__span {
      color: #0081a6;
      font-size: 1.25rem;
      margin: 1rem 0;
      font-weight: 700;
    }

    .m-lpi-learnmoregrid__description {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      line-height: 1.3;
      font-weight: 600;
    }

    .m-lpi-learnmoregrid__button {
      padding: 10px 20px;
      display: inline-block;
    }
  }

  &.mobile-only {
    .m-carousel-controls {
      justify-content: center;
      margin-top: 70px;
      display: flex;
    }

    .glider-dots {
      margin: 0;
    }

    .glider-dot {
      border: 2px solid $midnight;
      background: transparent;
      margin: 7px 22px;
      width: 20px;
      height: 20px;

      &.active {
        background: $midnight;
      }
    }

    .glider-x-prev {
      background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
      width: 20px;
      height: 25px;
      position: relative;
      top: 0;
      left: auto;
      margin-right: 40px;
      border: 0;
    }

    .glider-x-next {
      background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
      width: 20px;
      height: 25px;
      position: relative;
      top: 0;
      right: auto;
      margin-left: 40px;
      border: 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-lpi-learnmoregrid {
    &.m-practices-arrow {
      &::before {
        background-size: 100% 750px;
        bottom: -15%;
      }
    }

    .m-lpi-learnmoregrid__content {
      min-height: 100%;
      padding: 35px 15px 60px;
      margin: 0;

      h3 {
        min-height: 116px;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-lpi-learnmoregrid {
    .m-lpi-learnmoregrid__content {
      padding: 35px 20px 60px;

      h3 {
        min-height: 77px;
        padding: 0 50px;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-lpi-learnmoregrid {
    .m-lpi-learnmoregrid__content {
      padding: 35px 40px 60px;
    }
  }
}

.m-lpi-gradient {
  background: linear-gradient(#fff, #bde5ee, #0089b2, #131945);
  margin-bottom: 160px;

  .m-lpi-offsetimage {
    text-align: center;
    margin-bottom: -40px;

    img {
      max-width: 200%;
      transform: translateX(-22%);
    }
  }

  h2 {
    font-size: 2.375rem;
    margin-bottom: 24px;
  }

  p {
    padding-bottom: 20px;
  }

  .m-standard-button {
    display: inline-block;
  }
}

@media only screen and (max-width: 991px) {
  .m-lpi-gradient {
    margin-bottom: 40px;

    .m-lpi-offsetimage {
      img {
        &.mobile-half {
          width: 100%;
          transform: none;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-lpi-gradient {
    margin-bottom: 320px;
    overflow: visible;

    .container {
      margin-bottom: -160px;
    }

    .m-lpi-offsetimage {
      transform: translateY(160px);
      margin-top: -100px;
      margin-bottom: 0;

      img {
        max-width: 100%;
        transform: none;
      }
    }

    h2 {
      font-size: 3.125rem;
    }
  }
}
/* search page */
.m-search-herobg {
  background: linear-gradient(180deg, #246885 -40%, $midnight);
  text-align: center;
  padding: 85px 0 125px;

  .m-search-header {
    font-size: 50px;
    font-weight: 700;
    font-family: "Source Serif Pro";
    color: $white;
  }
}

@media only screen and (min-width: $desktop) {
  .m-search-herobg {
    padding: 125px 0;
  }
}

.search-results.light-blue {
  background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945);

  .container {
    background: $white;
    transform: translateY(-50px);
    padding: 50px 0;

    ul {
      padding: 0;

      li.result-item {
        padding: 24px 12px;
        margin: 0 20px;
        border-top: 1px solid #e6e6e6;
      }

      a {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.25;
        margin-bottom: 12px;
        display: block;
      }

      .text-body {
        font-size: 1.25rem;
        line-height: 1.25;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .search-results.light-blue {
    .container {
      padding: 80px 110px;

      ul {
        .text-body {
          font-size: 1.5rem;
        }
      }
    }
  }
}
/* faq page */
.gradient-faq {
  background: linear-gradient(180deg, #246885, $midnight 70%);
  padding: 74px 0 76px;

  h1 {
    font-size: 2.625rem;
    font-family: "Source Serif Pro", serif;
    margin-bottom: 9px;
    font-weight: 900;
  }

  p {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .gradient-faq {
    background: linear-gradient(180deg, #246885, $midnight 70%);

    h1 {
      font-size: 3.125rem;
      margin-bottom: 20px;
      font-weight: 700;
    }
  }
}

.accordion {
  margin-top: 14px;
  margin-bottom: 37px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;
  padding-left: 0;
}

@media only screen and (min-width: $desktop) {
  .accordion {
    margin-top: 34px;
  }
}

.accordion-item {
  border: 0;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 40px;

  &:last-of-type {
    border-radius: 0;
    border-bottom: 0;
  }
}

.accordion-button-faq {
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.25;
  color: #0081a6;
  text-align: left;
  border: none;
  background: none;
  position: relative;
  width: 100%;

  p {
    margin: 0;
    padding: 30px 0 0;
    padding-right: 50px;
  }

  &::after {
    background: url("/images/chevron-up.svg") no-repeat;
    display: block;
    content: "";
    width: 24px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.collapsed {
    &::after {
      background: url("/images/chevron-down.svg") no-repeat;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .accordion-button-faq {
    font-size: 2.125rem;
  }
}

.accordion-body-faq {
  padding: 16px 0 0;
  font-size: 1.25rem;
  line-height: 1.4;
  color: $midnight;

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .accordion-body-faq {
    padding: 16px 32px 0;
    font-size: 1.5rem;
  }
}

.m-container-faq__return {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  p {
    font-size: 1.25rem;
    font-weight: 700;
  }
}

@media only screen and (min-width: $desktop) {
  .m-container-faq__return {

    p {
      font-size: 1.5rem;
    }
  }
}

/* Certifications */
.m-certifcation-trainedcoach {
  background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945 120%);
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;

  .container {
    padding: 24px 0 0;
  }

  p {
    font-family: "Source Serif Pro", serif;
    color: $midnight;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 32px;
    font-weight: 700;

    &.text-24 {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  img {
    width: 200%;
    left: 44%;
    transform: translateX(-50%);
    position: relative;
  }
}

@media only screen and (min-width: $desktop) {
  .m-certifcation-trainedcoach {
    overflow: visible;
    margin-bottom: 0;

    .container {
      padding: 80px 20px 0;
      margin-bottom: 120px;
    }

    p {
      font-size: 32px;
      line-height: 42px;

      &.text-24 {
        font-size: 1.5rem;
      }
    }

    img {
      margin-bottom: -35px;
      width: 100%;
      left: 0;
      transform: translateX(-3%);
    }
  }
}

.m-certification-icongrid {
  .m-certification-icongrid__itm {
    padding: 32px 0;
    align-items: center;
    text-align: center;

    img {
      width: 102px;
      margin-bottom: 16px;
    }

    .m-certification-icongrid__itm-content {
      line-height: 24px;
      font-size: 1.25rem;
    }

    .m-certification-icongrid__itm-title {
      font-weight: 700;
      line-height: 30px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-certification-icongrid {
    .m-certification-icongrid__itm {
      display: flex;
      text-align: left;

      img {
        width: 136px;
        margin-right: 22px;
        margin-bottom: 0;
      }

      .m-certification-icongrid__itm-content {
        line-height: 33px;
        font-size: 1.5rem;
      }

      .m-certification-icongrid__itm-title {
        line-height: 34px;
      }
    }
  }
}

.m-certification-imagegrid {
  padding-top: 25px;
  padding-bottom: 80px;

  .glider-track {
    padding: 0;
  }

  .m-image-grid__item {
    > h3 {
      font-size: 40px;
      font-weight: 800;
      line-height: 54px;
      display: none;
    }

    > img {
      display: none;
    }
  }

  .m-image-grid__item-overlay {
    padding: 20px;
    padding-top: 58px;
    padding-bottom: 54px;
    display: block;
    height: 100%;
    text-align: center;
    align-content: center;

    &.midnight-bg {
      color: #e6e6e6;
    }

    h3 {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.3;
    }
  }

  .m-workshop-checklist {
    p {
      margin-bottom: 1rem;
    }
  }

  .m-carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    align-items: center;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 10px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 15px;
  }

  .glider-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 15px;
  }
}

.m-master-imagegrid {
  .glider-dots {
    .glider-dot:last-child {
      display: none;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .m-master-imagegrid {
    .m-image-grid__item-overlay {
      position: relative !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-certification-imagegrid:not(.m-master-imagegrid) {
    padding-top: 80px;


    .glider-dot {
      margin: 7px 22px;
    }

    .m-image-grid__item {
      > h3 {
        display: block;
      }

      > img {
        display: block;
      }
    }

    .m-image-grid__item-overlay {
      display: none;
      padding: 25px;

      &.show {
        display: block;
      }
    }

    .m-carousel-controls {
      display: none;
    }

    .m-imagegrid-padding {
      padding: 0 140px 8px;
    }

    img {
      width: 100%;
    }

    .container {
      max-width: 100%;
    }
  }

  .m-workshop-checklist {
    p {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-certification-imagegrid.m-master-imagegrid {
    padding-top: 80px;


    .glider-dot {
      margin: 7px 22px;
    }

    .m-image-grid__item {
      > h3 {
        display: block;
      }

      > img {
        display: block;
      }
    }

    .m-image-grid__item-overlay {
      display: none;
      padding: 25px;

      &.show {
        display: block;
      }

      p {
        font-size: 1.25rem;
      }
    }

    .m-carousel-controls {
      display: none;
    }

    .m-imagegrid-padding {
      padding: 0 140px 8px;
    }

    img {
      width: 100%;
    }

    .container {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-certification-imagegrid.m-master-imagegrid {
    .m-image-grid__item-overlay {
      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-certification-imagegrid {
    .m-image-grid__item-overlay {
      padding-top: 75px;

      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-certification-imagegrid {
    .container {
      max-width: 1440px;
    }
  }
}

.m-short-arrow {
  position: relative;
  padding-bottom: 180px !important;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    background: url("/images/certifications/short-arrow.jpg") no-repeat;
    display: block;
    height: 100%;
    background-position: center bottom;
  }
}

.m-certification-testimonial {
  padding-bottom: 0;
}

.m-certification-events {
  background: linear-gradient(180deg,transparent,#bde5ee,#0089b2 40%,#131945 70%);
  position: relative;
  padding-top: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: url("/images/certifications/certifications-topography.png") no-repeat;
    background-position: top;
    background-size: cover;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .m-certification-formmore {
    font-size: 24px;
    line-height: 34px;

    a {
      font-weight: 700;
    }
  }

  .m-certification-formheader {
    background: $white;
    padding: 46px 60px 0;
    margin-top: 94px;
    margin-bottom: -1px;

    h2 {
      margin-bottom: 12px;
      text-align: center;
    }

    p {
      margin-bottom: 0;
      font-size: 1.25rem;
      line-height: 1.4;
      text-align: left;
    }
  }

  form {
    padding-top: 0 !important;
    padding-bottom: 117px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-certification-events {
    padding-top: 140px;

    .m-certification-formheader {
      padding: 46px 190px 0 !important;

      p {
        text-align: center;
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-certification-events {
    .m-certification-formheader {
      padding: 60px 190px !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-certification-events {
    padding-top: 140px;
  }
}

.m-practitioner-certifications {
  text-align: center;

  img {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #808080;
    font-style: italic;
    margin: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-practitioner-certifications {
    text-align: center;

    img {
      padding: 0 40px;
      margin-bottom: 32px;
    }

    p {
      padding: 0 330px;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-practitioner-certifications {
    p {
      padding: 0 330px;
    }
  }
}

.m-image-grid__master-tile {
  padding: 32px;
  align-self: center;

  h3 {
    font-family: "Source Serif Pro", serif;
    font-size: 2rem;
    line-height: 44px;
    margin-bottom: 12px;
    font-weight: 800;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    line-height: 34px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-image-grid__master-tile {
    padding: 32px;
    align-self: center;

    h3 {
      font-family: "Source Serif Pro", serif;
      font-size: 3.125rem;
      line-height: 62px;
    }

    p {
      font-size: 1.5rem;
    }
  }
}

.m-quote-carousel {
  padding: 0 !important;

  .glider-slide {
    padding: 40px 64px 0;
  }

  .glider-q-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 40px;
    border: 0;
  }

  .glider-q-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 40px;
    border: 0;
  }

  .glider {
    > div {
      position: relative;
    }
  }

  .m-carousel-controls {
    text-align: right;
    padding-right: 70px;
  }
}

/* Training events calendar */
.m-training-header {
  background: linear-gradient(180deg, #246885, $midnight);
  padding: 64px 0 75px;
  text-align: center;

  h1 {
    font-size: 2.25rem;
    line-height: 43px;
    font-weight: 700;
    color: $white;
    font-family: "Source Serif Pro", serif;
    margin-bottom: 20px;
  }

  p {
    color: $white;
    font-size: 1.375rem;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.m-training-tabs {
  .col-12 {
    padding: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-training-header {
    padding: 100px 0 80px;

    h1 {
      font-size: 3.125rem;
      line-height: 55px;
    }

    p {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-training-header {
    padding: 100px 260px;

    h1 {
      font-size: 3.125rem;
    }

    p {
      font-size: 1.5rem;
    }
  }
}

.m-training-tabs__group {
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  a {
    background: #f6f6f6;
    border: 3px solid $white;
    text-decoration: none;
    font-size: 1.375rem;
    font-weight: 600;
    width: 100%;
    height: 94px;
    text-align: center;
    align-content: space-evenly;
    padding: 0 90px;
    line-height: 1.2;

    &.active,
    &:hover {
      background: #ef561f;
      border-color: #ef561f;
      color: $white;
      font-weight: 700;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-training-tabs__group {
    flex-direction: row;

    a {
      padding: 0 20px;
    }
  }
}

/* Certified masters */
.m-certified-masters {
  background: linear-gradient(180deg, #131945, #0089b2, #bde5ee, #fff);
  position: relative;

  .container {
    position: relative;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: url("/LeadershipChallenge/media/media/certifications/certified-masters-topology.png") no-repeat;
    background-position: center;
  }

  .m-research-searchbox {
    padding: 60px 110px;

    h2 {
      font-size: 2.5rem;
      line-height: 38px;
    }

    .search-form {
      display: flex;
      align-items: flex-end;
      gap: 15px;
      justify-content: center;

      .m-form-select {
        max-width: 300px;
      }
    }
  }

  button {
    cursor: pointer;
    font-size: 1.25rem;
    margin: 0 16px;

    &.m-inverse-button {
      border: 0;
      padding: 10px 20px;
      text-decoration: underline;

      &:hover {
        background: $white;
      }
    }
  }

  select {
    padding: 14px;
  }

  label {
    margin-bottom: 10px;
  }

  button:hover {
    background-color: #d35400;
  }

  .m-research-searchbox .search-form > div {
    flex: 1;
  }

  .m-research-searchbox .search-form select,
  .m-research-searchbox .search-form button {
    width: 100%;
  }

  .card-container {
    padding: 16px 0;
    height: 100%;

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 16px;
    }

    .card-body {
      background: $white;
      box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
      padding: 52px 32px;
      height: 100%;
      font-size: 1.25rem;
      line-height: 25px;

      img {
        width: 100%;
      }

      h5 {
        font-family: "Source Serif Pro", serif;
        font-size: 2rem;
        line-height: 40px;
        color: #0081a6;
        margin-bottom: 16px;
        font-weight: 700;
      }
    }

    .m-standard-button {
      margin: 0;
      padding: 10px 20px;

      &.desktop-only {
        display: inline-block;
      }

      &.mobile-only {
        display: none;
      }
    }

    .card-body-content {
      padding: 0 12px;
      text-align: left;
    }

    .card-body-content-contact {
      margin-bottom: 16px;
      font-size: 1rem;

      p {
        margin-bottom: 0;
      }
    }

    .card-text.card-text-location {
      margin-bottom: 16px;
      font-size: 1rem;
    }
  }

  .loading {
    text-align: center;
    font-size: 1.2em;
    margin: 20px 0;
  }

  .error {
    color: #ff0000;
    text-align: center;
    font-size: 1.2em;
    margin: 20px 0;
  }

  .m-research-searchbox select option[value=""] {
    color: #cbcbcb;
  }

  .m-research-searchbox select:invalid {
    color: #cbcbcb;
  }

  .m-research-searchbox select option {
    color: #000;
  }

  .m-research-searchbox .search-form .button-group {
    display: flex;
    gap: 10px;
    max-width: 320px;
  }
}

@media (min-width: $phone) {
  .card-body-content-contact {
    font-size: 1.2rem !important;
  }

  .card-text.card-text-location {
    font-size: 1.2rem !important;
  }
}

@media (min-width: $desktop) {
  .card-body-content-contact {
    font-size: 1.25rem !important;
  }

  .card-text.card-text-location {
    font-size: 1.25rem !important;
  }
}

@media (max-width: 991px) {
  .m-certified-masters {
    .m-research-searchbox {
      padding: 7px 20px 24px;

      h2 {
        font-size: 2rem;
        margin-bottom: 25px;
      }

      .search-form {
        flex-direction: column;

        .m-form-select {
          max-width: 100%;
        }
      }
    }

    .card-container {

      .card-body {
        text-align: center;

        img {
          display: block;
          margin-bottom: 24px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .m-standard-button {
        &.desktop-only {
          display: none;
        }

        &.mobile-only {
          display: inline-block;
        }
      }
    }

    .card-body-content {
      padding: 0 12px;
      text-align: center;
    }

    .m-research-searchbox .search-form > div {
      width: 100%;
    }

    .m-research-searchbox .search-form .button-group {
      display: block;
      gap: 10px;
      max-width: 100%;
    }

    button {
      margin: 0;
    }

    .m-card-column {
      padding: 0 12px;
    }
  }
}

.m-certified-master__detail {
  padding: 50px 0 48px;

  img {
    width: 100%;
    margin-bottom: 12px;
    max-width: 220px;
  }

  .m-certified-master__detail-contact {
    text-align: center;
    font-size: 1.25rem;

    p {
      margin-bottom: 0;
    }

    margin-bottom: 22px;
  }

  .m-certified-master__detail-location {
    text-align: center;
    margin-bottom: 35px;
    font-size: 1.25rem;
  }

  .m-certified-master__detail-badge {
    width: 159px;
    margin-bottom: 40px;
  }

  .m-certified-master__detail-left {
    text-align: center;
  }

  .m-certified-master__detail-right {
    p {
      font-size: 1.25rem;
      line-height: 28px;
      margin-bottom: 28px;
    }

    .m-certified-master__detail-btn {
      margin-bottom: 0;
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-certified-master__detail {
    padding: 60px 0 80px;

    img {
      margin-bottom: 32px;
    }

    .m-certified-master__detail-contact {
      text-align: left;
      margin-bottom: 16px;
    }

    .m-certified-master__detail-location {
      text-align: left;
      margin-bottom: 32px;
    }

    .m-certified-master__detail-badge {
      width: 181px;
      margin-bottom: 0;
    }

    .m-certified-master__detail-left {
      text-align: left;
    }

    .m-certified-master__detail-right {
      p {
        font-size: 1.5rem;
        line-height: 32px;
      }

      .m-certified-master__detail-btn {
        font-size: 1.5rem;
      }
    }
  }
}

.card-body-image__crop {
  width: 220px;
  margin: 20px auto;
  height: 220px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

@media only screen and (min-width: $large-desktop) {
  .card-body-image__crop {
    width: 100%;
    margin: 0;
    height: 137px;
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .card-body-image__crop {
    width: 100%;
    margin: 0;
    height: 215px;
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .card-body-image__crop {
    width: 100%;
    margin: 0;
    height: 240px;
  }
}

.m-certified-master__detail-left {
  .card-body-image__crop {
    height: 220px;
  }
}
/* Sales */
.m-sales-contact {
  padding: 40px 0 100px;

  h2 {
    font-size: 2.25rem;
    line-height: 45px;
    margin-bottom: 32px;
  }

  .m-sales-tile {
    padding: 0 8px;
    text-align: center;
    margin-bottom: 60px;

    p {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
      margin-bottom: 32px;
    }

    .m-standard-button {
      display: inline-block;
    }
  }
}

.m-sales-shop__banner {
  background: #f2f2f2;
  padding: 32px 20px;
  font-size: 1.25rem;
  line-height: 28px;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  a {
    font-weight: 700;
  }
}

@media only screen and (min-width: $desktop) {
  .m-sales-contact {
    padding: 80px 0 100px;

    .m-sales-tile {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-sales-shop__banner {
    padding: 32px 250px;
    font-size: 1.5rem;
  }
}

.m-sales-grid {
  padding: 48px 0 50px;

  .m-sales-grid__header {
    text-align: center;
    padding: 0 50px 60px;
    padding-bottom: 60px;

    h2 {
      font-size: 38px;
      line-height: 50px;
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 0;
      font-size: 1.25rem;
      line-height: 28px;
    }
  }

  .m-shop-tile {
    padding: 0 16px;
    text-align: center;
    margin-bottom: 60px;
    padding-top: 40px;

    .m-shop-tile__image {
      background: #f2f2f2;
      padding-bottom: 32px;
      margin-bottom: 20px;

      img {
        height: 248px;
        width: auto;
        margin-top: -40px;
        box-shadow: 0 20px 32px rgba(0, 0, 0, .16);
      }

      &.m-noshadow-img {
        padding-bottom: 0;

        img {
          height: 282px;
          box-shadow: none;
        }
      }
    }

    h3 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
      font-family: "Source Serif Pro", serif;
      font-weight: 700;
    }

    p {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    .m-standard-button {
      display: inline-block;
      padding: 10px 20px;
      font-size: 1.125rem;
      line-height: 22px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-sales-grid {
    padding: 80px 0 50px;

    .m-sales-grid__header {

      h2 {
        font-size: 40px;
      }

      p {
        font-size: 1.5rem;
        line-height: 34px;
      }
    }
  }
}

.m-sales-book {
  padding-top: 0;

  .m-practices-ebook__image::before {
    left: auto;
    right: 39%;
  }

  h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 24px;
  }

  p {
    font-size: 1.25rem;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: left;
  }

  img {
    width: 100%;
  }
}

@media only screen and (min-width: $desktop) {
  .m-sales-book {
    padding-top: 50px;

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 1.5rem;
      line-height: 34px;
    }


    .m-practices-ebook__content {
      padding-left: 100px;
    }

    &.container {
      max-width: 100%;
      padding-bottom: 180px;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-sales-book {
    h2 {
      padding-right: 100px;
    }

    .m-practices-ebook__content {
      padding-left: 100px;
      padding-right: 80px;
    }
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-sales-book {
    &.container {
      max-width: 1440px;
    }
  }
}

.m-demo-topology {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("/images/sales/demo-topology-mobile.jpg") no-repeat;
    background-size: cover;
    display: block;
    z-index: -1;
  }
}

@media only screen and (min-width: $desktop) {
  .m-demo-topology {
    &::before {
      background: url("/images/sales/demo-topology.jpg") no-repeat;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-demo-topology {
    .m-certification-icongrid {
      h2 {
        font-size: 3.125rem;
        margin-bottom: 12px;
      }
    }
  }
}

.m-demo-tiles {
  padding-top: 60px;

  h3 {
    text-align: center;
    padding: 0 50px;
  }

  &.m-practices-arrow {
    &::before {
      bottom: -10%;
      width: 100%;
      transform: translateX(100%);
    }
  }

  .m-practice-tile__content {
    text-align: center;
  }
}

.m-demo-questions {
  padding: 32px 20px 53px;
  background: $white;
  text-align: center;
  position: relative;

  a {
    font-weight: 700;
  }
}

@media only screen and (min-width: $desktop) {
  .m-demo-questions {
    padding: 32px 112px 53px;
  }
}

.m-overlay-image {
  padding: 0 0 70px;

  h2 {
    text-align: center;
    font-size: 2.375rem;
    line-height: 46px;
    margin-bottom: 24px;
  }

  p {
    font-size: 1.25rem;
    line-height: 30px;
    margin-bottom: 24px;
  }

  .m-standard-button {
    display: inline-block;
    margin: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-overlay-image {
    padding: 50px 0;
    position: relative;

    h2 {
      text-align: left;
      font-size: 2.5rem;
      line-height: 50px;
    }

    p {
      font-size: 1.5rem;
      line-height: 33px;
    }

    .m-standard-button {
      left: 0;
      transform: none;
    }

    img {
      max-width: 100%;
      width: auto;
      transform: translateX(40px);
    }

    .m-overlay-image__image {
      text-align: right;
    }

    .m-overlay-image__text {
      position: absolute;
      width: 56%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 40px 64px;
      padding-left: 0;
      background: $white;
    }
  }
}

/* inline video */
.m-inline-video {
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.m-inline-video__gradient {
  padding-top: 80px;
  background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945 150%);
}

@media only screen and (max-width: 991px) {
  .m-inline-video__full {
    padding: 0;
  }

  .m-inline-video__gradient {
    padding-left: 0;
    padding-right: 0;

    .container {
      padding: 0;

      .m-inline-video {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-inline-video {
    padding: 0 130px;
  }

  .m-inline-video__gradient {
    padding-top: 170px;
    margin-bottom: 65px;

    .m-inline-video {
      margin-bottom: -65px;
    }
  }
}

/* Facilitation sets */
.m-facilitation-header {
  padding: 56px 20px;

  h1 {
    font-weight: 900;
    line-height: 50px;
  }
}

@media only screen and (max-width: 1100px) {
  .m-facilitation-header {
    .container {
      padding: 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-facilitation-header {
    padding: 150px 170px;
  }
}

.m-facilitation-set {
  background: linear-gradient(180deg, #fff 35%, #bde5ee, #0089b2, #131945);
  position: relative;
  padding-top: 64px;

  &::before {
    background: url("/images/authors/author-timeline-topogrophy.png") no-repeat;
    background-size: cover;
    background-position: top;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container {
    position: relative;
  }

  .m-facilitation-set__intro {
    padding: 0 20px;

    img {
      margin-bottom: 37px;
      width: 296px;
    }

    h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 2.175rem;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .m-faciliation-set__tiles {
    margin-top: 80px;
    margin-bottom: 30px;
  }

  .m-faciliation-set__tile {
    padding: 16px;
  }

  .m-faciliation-set__tile-content {
    background: $white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
    padding: 40px 32px 32px;
    height: 100%;
    text-align: center;

    img {
      width: 100%;
      margin-bottom: 32px;
    }

    .m-faciliation-set__tile-title {
      margin-bottom: 12px;
      font-size: 1.5rem;
      line-height: 34px;
      font-weight: 700;

      a {
        margin-top: 32px;
        font-size: 1rem;
        line-height: 20px;
        font-weight: 700;
        display: block;
      }
    }

    .m-faciliation-set__tile-description {
      margin-bottom: 0;
      font-size: 1.25rem;
      line-height: 34px;

      a {
        margin-top: 0;
        display: inline;
      }
    }
  }

  .m-carousel-controls {
    justify-content: center;
    margin-top: 70px;
    display: flex;
  }

  .f-glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-f-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 20px;
    border: none;
  }

  .glider-f-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 20px;
    border: none;
  }
}

.m-video-facilitation {
  padding: 0;
}

@media (max-width: $phone) {
  .m-facilitation-set .glider-dot {
    margin: 7px 12px;
  }
}

.m-facilitation-arrow.m-practices-arrow {
  &::before {
    bottom: -30%;
  }
}

@media only screen and (min-width: $tablet) {
  .m-facilitation-arrow.m-practices-arrow {
    &::before {
      bottom: -90%;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-facilitation-arrow.m-practices-arrow {
    &::before {
      bottom: 0;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-facilitation-arrow.m-practices-arrow {
    &::before {
      bottom: 25%;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-facilitation-arrow.m-practices-arrow {
    &::before {
      bottom: 45%;
    }
  }

  .m-video-facilitation {
    padding: 0 130px;
  }
}

.m-video-more {
  text-align: center;

  .m-standard-button {
    display: inline-block;
    margin: 0;
    margin-top: 60px;
  }
}

.m-facilitation-whitebg {
  background: $white;
  padding: 40px 20px;
  margin-top: 60px;

  h2 {
    font-size: 2.125rem;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
  }
}

.m-faciliation-button {
  text-align: center;

  .m-standard-button {
    display: inline-block;
  }
}

@media only screen and (min-width: $desktop) {
  .m-facilitation-set {
    padding-top: 86px;

    .m-facilitation-set__intro {
      text-align: center;
      padding: 0 98px;

      img {
        margin-bottom: 40px;
        padding: 0;
        width: auto;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 2.5rem;
      }

      p {

        &:last-child {
          margin-bottom: 60px;
        }
      }
    }

    .m-faciliation-set__tiles {
      margin-top: 12px;
      margin-bottom: 60px;
      font-size: 1.5rem;
      line-height: 36px;
    }

    .m-faciliation-set__tile-content {
      padding: 40px 32px 32px;
      height: 100%;

      .m-faciliation-set__tile-title {
        font-size: 1.75rem;
      }

      .m-faciliation-set__tile-description {
        margin-bottom: 0;
        font-size: 1.5rem;
      }

      a {
        font-size: 1.5rem;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }

  .m-facilitation-whitebg {
    background: $white;
    padding: 80px 90px;
    margin-top: 100px;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.m-blue-grid__content {
  h2 {
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blue-grid__content {
    h2 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 60px;
    }
  }
}

.m-blue-grid__tile {
  height: 100%;
  position: relative;
  padding: 0 16px;

  &::before {
    content: "";
    background: $white;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: calc(100% - 32px);
    margin: 0 16px;
    z-index: -1;
  }

  h3 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
    font-family: "Source Serif Pro", serif;
  }

  img {
    width: 100%;
    position: relative;
  }
}

.m-blue-grid__tile-content {
  padding: 24px 16px;
  background: $white;
  color: $midnight;
  text-align: left;

  &.text-center {
    text-align: center;
  }

  h4 {
    font-size: 1.75rem;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 16px;
    font-family: "Source Serif Pro", serif;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $midnight;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 25px;

    &.m-blue-grid__tile-hours {
      font-size: 1.25rem;
      line-height: 1.2;
      font-weight: 700;
      color: #0081a6;
    }

    &.last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-blue-grid__tile {
    padding: 0 0 16px;

    &::before {
      content: "";
      background: $white;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50%;
      width: calc(100%);
      margin: 16px 0;
      z-index: -1;
    }
  }

  .m-blue-grid__tile-content {
    padding: 24px 32px;

    h4 {
      font-size: 2rem;
      line-height: 40px;
    }

    p {
      font-size: 1.5rem;
      line-height: 30px;

      &.text-20 {
        line-height: 25px;
      }

      &.m-blue-grid__tile-hours {
        font-size: 1.25rem;
      }
    }
  }

  .m-blog-featured.tlc-form {
    div[id^="form"] {
      margin-top: -100px;
      height: calc(100% + 100px);
    }

    .m-blog-list {
      margin-top: -100px;
    }

    form {
      padding: 64px 42px;
      height: 100%;
    }
  }
}

/* Blog */
@media only screen and (min-width: $x-large-desktop) {
  .midnight-bg {
    &.m-blog-gradient {
      padding-bottom: 300px;
      background: linear-gradient(180deg, #246885, $midnight 50%);
    }
  }

  .m-blog-featured.tlc-form {
    overflow: visible !important;

    div[id^="form"],
    .m-blog-list {
      margin-top: -100px;
      background: $white;
    }

    form {
      padding: 32px 42px;
    }
  }
}


.m-blog-carousel {
  padding: 66px 40px 40px;

  h2 {
    margin-bottom: 24px;
    text-align: center;
  }

  .glider-slide {
    align-content: flex-start;
  }

  .m-carousel-controls {
    justify-content: center;
    margin-top: 70px;
    display: flex;
    align-items: center;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $midnight;
    background: transparent;
    margin: 7px 12px;
    width: 20px;
    height: 20px;

    &.active {
      background: $midnight;
    }
  }

  .glider-bl-prev {
    background: url("/images/assessments/chevron-blue-left.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    left: auto;
    margin-right: 20px;
    border: 0;
  }

  .glider-bl-next {
    background: url("/images/assessments/chevron-blue-right.svg") no-repeat;
    width: 20px;
    height: 25px;
    position: relative;
    top: 0;
    right: auto;
    margin-left: 20px;
    border: 0;
  }

  .article {
    padding: 0 10px;
  }

  .glider-track {
    padding: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-carousel {
    padding: 80px 0;

    h2 {
      margin-bottom: 40px;
    }

    .m-carousel-controls {
      display: none;
    }

    .article {
      padding: 0;
    }

    .glider-dot {
      margin: 7px 22px;
    }
  }
}

.blog-list {
  text-align: left;

  a {
    text-decoration: none;

    &:hover {
      .lead {
        text-decoration: underline;
      }

      img {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
      }
    }
  }

  img {
    margin-bottom: 24px;
    max-width: 100%;
  }

  .article-body {
    .lead {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 12px;
    }

    small {
      font-size: 1.125rem;
      line-height: 22px;
      color: #333;
      font-weight: 400;
    }
  }
}

.m-blog-featured {
  padding-top: 0;

  &.tlc-form {
    overflow: visible;

    .container {
      margin-top: -100px;
      background: $white;
      margin-bottom: 24px;
    }

    .ebook-details__title {
      margin-top: 0;
      padding-top: 0;
    }

    form {
      box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
      padding: 40px 40px 60px;
    }
  }

  .m-blog-list {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);

    .m-blog-list__item {
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      margin-bottom: 32px;
    }

    .featured {
      font-size: 2rem;
      line-height: 40px;
      text-align: center;
      margin-bottom: 57px;
      padding: 0;

      a {
        text-decoration: none;
      }
    }

    .title {
      font-size: 1.75rem;
      font-weight: 700;
      font-family: "Source Serif Pro", serif;
      padding: 0 24px;
      margin-bottom: 10px;
    }

    small {
      font-size: 1rem;
      color: #0081a6;
      margin-bottom: 16px;
      font-weight: 700;
      padding: 0 24px;
    }

    p {
      font-size: 1.25rem;
      line-height: 25px;
      padding: 0 24px;
      text-align: left;

      a {
        text-decoration: none;
      }
    }

    .m-inverse-button {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (max-width: 1399px) {
  .m-blog-featured.tlc-form {
    .col-md-6 {
      width: 100%;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-featured {
    &.tlc-form {
      .container {
        margin-bottom: 0;
        height: calc(100% + 100px);
      }
    }

    .m-blog-list {
      .m-blog-list__item {
        margin-bottom: 32px;
      }

      .title {
        font-size: 2rem;
        padding: 0 24px;
      }

      small {
        font-size: 1.25rem;
      }

      p {
        text-align: center;
      }
    }
  }
}

.m-blog-spotlight {
  margin-bottom: 40px;
  padding-bottom: 0;

  .spotlight {
    > .col-12 {
      margin-bottom: 45px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }

  a {
    text-decoration: none;

    &:hover {
      img {
        box-shadow: 2px 2px 10px rgba(0,0,0,.25);
      }

      .title {
        text-decoration: underline;
      }
    }
  }

  .title {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  small {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: 400;
    color: #333;
  }

  img {
    margin-bottom: 12px;
    width: 100%;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-spotlight {
    margin-bottom: 30px;
    line-height: 37px;

    .title {
      font-size: 30px;
    }
  }
}

.m-blog-all {
  padding-top: 0;
  margin-bottom: 80px;

  h2 {
    margin-bottom: 32px;
    text-align: center;
  }

  .m-carousel-controls {
    display: none;
  }

  .m-loadmore-button {
    margin-top: 76px;
    text-align: center;
  }

  .m-blog-list__item {
    margin-bottom: 45px;
  }

  a {
    text-decoration: none;
  }

  img {
    margin-bottom: 24px;
  }

  .title {
    font-size: 1.5rem;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  small {
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: 400;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-all {
    padding-top: 40px;

    .m-blog-list__item {
      margin-bottom: 32px;
    }
  }
}

/* whitepapers */
.m-leadership-unlocked {
  padding: 25px 0 50px;
  text-align: center;

  p {
    font-size: 1.25rem;
    line-height: 30px;
  }
}

.m-blog-detail {

  .m-blog-detail__hero-image {
    > img {
      padding: 0;
      width: 100%;
      max-width: 1440px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.m-leadership-unlocked__image {
  position: relative;
  padding: 24px 0;
  text-align: center;
  margin-top: 30px;

  img {
    position: relative;
    max-width: 100%;
    text-align: center;
  }
}

.ebook-details__text {
  font-size: 1.25rem;
}

@media only screen and (min-width: $desktop) {
  .m-leadership-unlocked__container {
    padding: 0 40px;
  }

  .ebook-details__text {
    font-size: 1.5rem;
  }


  .m-leadership-unlocked {
    p {
      font-size: 1.5rem;
      line-height: 38px;
    }
  }

  .m-blog-detail {
    .container {
      margin-top: -60px;
      background: $white;
      position: relative;
    }

    .m-blog-info {
      small {
        font-size: 1.125rem;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-leadership-unlocked {
    padding: 75px 0;
  }

  .m-leadership-unlocked__image {
    margin-top: 0;

    img {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .m-leadership-unlocked__container {
    padding: 0;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
  }
}


@media only screen and (min-width: $x-large-desktop) {
  .m-blog-detail {
    .container {
      max-width: 1088px;
    }
  }
}

.m-blog-info {
  text-align: center;
  padding: 44px;


  .m-intelligence-logo {
    img {
      max-width: 100%;
      margin-bottom: 34px;
    }
  }

  .title {
    font-family: "Source Serif Pro", serif;
    font-size: 2.25rem;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  small {
    font-size: 1rem;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #333;
  }

  .m-blog-author {
    font-size: 1.25rem;
    line-height: 28px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-info {
    padding: 64px;

    .m-intelligence-logo {
      img {
        max-width: 480px;
        margin-bottom: 64px;
      }
    }

    .title {
      font-family: "Source Serif Pro", serif;
      font-size: 3.125rem;
      line-height: 55px;
    }

    .m-blog-author {
      font-size: 1.5rem;
      line-height: 32px;
    }
  }
}

.m-blog-content {
  p {
    font-size: 1.25rem;
    line-height: 32px;
    margin-bottom: 20px;

    &.push-btm-30 {
      margin-bottom: 30px;
    }
  }

  h2 {
    text-align: center;
    color: #0081a6;

    strong {
      font-weight: 700;
      font-family: "Source Serif Pro", serif;
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 32px;
      text-align: center;
    }
  }

  h3 {
    text-align: center;
  }

  img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .m-blog-content__container {
    margin: 0 20px;
    width: auto;
    background: $white;
    margin-top: -20px;
    padding: 0;
    position: relative;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blog-content {

    p {
      font-size: 1.5rem;
    }
  }
}

.m-blog-detail__hero-image {
  padding: 0;
}

.tlc-blog-form {
  padding-top: 64px;

  form {
    padding: 40px 40px 60px !important;
  }

  .ebook-details__title {
    padding-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .tlc-blog-form {
    form {
      padding: 0 !important;
    }
  }
}

.gradient-lt-blue-blog {
  > .container {
    margin-top: 30px;
  }

  .m-practices-social {
    padding: 60px 0 30px;

    span.text-32 {
      color: $midnight;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-leadership-unlocked {
    text-align: left;

    p {
      text-align: left;
    }
  }
}

/* Media */
.m-media-twocol {
  padding-top: 45px;

  .m-blue-grid__tile {
    padding: 0;

    h3 {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 800;
      font-size: 1.75rem;
      line-height: 43px;
      margin-bottom: 8px;
      text-align: left;
    }

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 1.25rem;
      line-height: 30px;
      color: $white;
    }

    &::before {
      content: none;
    }

    .m-media-learnmore {
      margin-top: 60px;
      text-align: center;
    }
  }

  .m-assessment-list {
    margin: 24px 0 60px;
  }

  .m-blue-grid__media-content {
    a {
      color: $white;
    }
  }

  .m-media-learnmore {
    margin-top: 54px;

    a {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-media-twocol {

    .m-blue-grid__tile {

      h3 {
        font-size: 32px;
      }

      p {
        font-size: 1.5rem;
        line-height: 30px;
        color: $white;
      }
    }
  }
}

.m-media-form {
  padding-top: 55px;
  padding-bottom: 110px;

  .container {
    margin-top: 0 !important;
  }

  form {
    padding: 30px 40px 50px !important;
  }

  h2 {
    font-size: 2.25rem;
    text-align: center;
  }

  p {
    font-size: 1.5rem;
  }

  .ktc-checkbox {
    label {
      color: #11171d;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .m-media-form {
    padding-top: 110px;

    form {
      padding: 64px 112px !important;
    }
  }
}

.m-content-hero__content-media {
  position: relative;

  img.m-media-hero__stat {
    position: absolute;
    z-index: 1;
    transform: translateY(180px) scale(.5);
    right: -80px;
    width: 400px !important;
    height: auto !important;
    bottom: 0;
  }

  .m-content-hero__content {
    padding-bottom: 0;

    h1 {
      margin-bottom: 12px;
    }
  }

  .m-media-content {
    background: #e9e9e9;
    padding-bottom: 24px;
    padding-top: 35px;
    text-align: center;

    p {
      margin: 0;
      font-size: 1rem;

      a {
        font-weight: 700;
      }
    }
  }
}

.media-content-img {
  margin-bottom: 32px;

  img {
    height: 172px;
  }
}

@media only screen and (min-width: $desktop) {
  .media-content-img {
    img {
      height: auto;
    }
  }
}

.m-media-link {
  color: $white;
  font-size: 20px;
  line-height: 25px;

  &:hover {
    color: #c1f4ff;
  }
}

.m-media-controls {
  &.m-carousel-controls {
    justify-content: center;
    margin-top: 20px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $white;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $white;
    }
  }

  .glider-me-prev {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }

  .glider-me-next {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }
}

.m-blue-grid__media-tiles {
  .m-assessment-list .m-assessment-list__content-indent li::marker {
    color: #25cae1;
  }

  .m-assessment-list .m-assessment-list__list li::before {
    background: url("/LeadershipChallenge/media/media/media/light-blue-checkmark.svg") no-repeat;
    background-size: contain;
  }

  .m-blue-grid__tile {
    padding-right: 24px;
  }

  .m-carousel-controls {
    justify-content: center;
    margin-top: 70px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $white;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $white;
    }
  }

  .glider-bg-prev {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }

  .glider-bg-next {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }
}

@media only screen and (max-width: 991px) {
  .m-content-hero__content-media {
    .m-media-content {
      max-width: 100%;
    }
  }

  .m-media-icons {
    img {
      display: block;
      max-width: 100% !important;
    }
  }

  .m-blue-grid__media-content {
    padding-right: 24px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-content-hero__content-media {

    img.m-media-hero__stat {
      transform: translateY(100px) scale(.8);
      right: 50px;
    }

    .m-media-content {
      background: transparent;
      text-align: left;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding-bottom: 38px;

      p {
        font-size: 1.25rem;
      }
    }
  }

  .m-media-icons {
    img {
      width: 20%;
      padding: 0 8px;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-content-hero__content-media {

    img.m-media-hero__stat {
      transform: translateY(100px) scale(.9);
      right: 50px;
    }
  }

  .m-media-icons {
    img {
      width: 20%;
      padding: 0 38px;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-content-hero__content-media {
    .m-content-hero__container {
      padding-top: 180px;
      padding-bottom: 150px;

      .m-content-hero__image {
        max-width: none;
      }
    }

    .m-content-hero__content {
      .col-12 {
        padding-right: 50px !important;
      }
    }

    img.m-media-hero__stat {
      transform: translateY(120px);
      scale: 1;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-content-hero__content-media {
    .m-content-hero__container {
      padding-top: 200px;
      padding-bottom: 203px;
    }

    .m-content-hero__content {
      .col-12 {
        padding-right: 50px;
      }
    }

    img.m-media-hero__stat {
      transform: translateY(100px);
    }
  }
}

/* media hero */
.m-content-hero.m-media-hero .m-content-hero__container .m-content-hero__image-container::before {
  transform: none !important;
}

@media only screen and (min-width: $desktop) {
  .m-content-hero__content-media.m-media-hero .m-content-hero__container .m-content-hero__image {
    transform: translateX(100px);
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-content-hero__content-media.m-media-hero .m-content-hero__container .m-content-hero__image {
    transform: none;
  }

  .m-content-hero.m-media-hero .m-content-hero__container .m-content-hero__image-container::before {
    transform: translateX(-30vw) !important;
  }
}

@media only screen and (min-width: $xx-large-desktop) {
  .m-content-hero.m-media-hero .m-content-hero__container .m-content-hero__image-container::before {
    transform: translateX(-60%) !important;
  }
}

.m-sign-in-hero {
  padding-left: 20px;
  padding-right: 20px;
}
/* Resource hub */
@media only screen and (max-width: 991px) {
  .m-sign-in-hero {
    padding-bottom: 100px;
  }

  #sign-in {
    margin: 40px 0 !important;
  }
}

.sign-in-col-left {
  padding: 0;

  .m-signin-leftcoloffset {
    padding: 23px;
    background: $white;
    margin-top: -100px;
    padding-top: 32px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, .08);
  }

  h2 {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
  }

  input {
    margin-bottom: 20px;
  }

  .remember-me {
    display: flex;
    align-items: baseline;

    label {
      font-weight: 400;
      margin-left: 24px;
      margin-bottom: 0;
      color: #11171d;
    }

    margin-bottom: 32px;
  }

  #forgot-password-text {
    display: block;
    text-align: center;
    color: #11171d;
    font-size: 20px;
  }

  p {
    color: #11171d;

    a {
      text-decoration: underline;
    }
  }

  .btn.btn-primary {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .forgot-password-text {
    color: #11171d;
  }

  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.sign-in-col-right {
  padding-left: 20px;
  padding-right: 20px;

  img {
    width: 100%;
    margin-top: 36px;
  }
}

@media only screen and (min-width: $desktop) {
  .sign-in-col-right {
    img {
      margin-top: 0;
    }
  }
}

.forgot-password-page {
  margin: 40px;

  h2 {
    font-size: 2.25rem;
    line-height: 40px;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    text-align: center;
  }

  .col-lg-6 {
    &:first-child {
      background: $white;
      padding-top: 32px;
    }

    &:last-child {
      img {
        width: 100%;
      }
    }
  }

  label {
    font-weight: 700;
  }

  input[type="email"] {
    width: 100%;
    height: 3rem;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  input[type="submit"] {
    color: #fff;
    background: #ef561f;
    border: 2px solid #ef561f;
    padding: 10px 60px;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.25rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.signin-background {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: $desktop) {
  .forgot-password-page {
    margin: 40px;

    p {
      font-size: 1.25rem;
      text-align: center;
    }

    .col-lg-6 {
      &:first-child {
        margin-top: -100px;
      }

      &:last-child {
        img {
          margin-top: -100px;
        }
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .forgot-password-page {
    h2 {
      padding: 0 85px;
    }
  }
}

#sign-in-page {
  .form-group {
    position: relative;

    button {
      color: #121e3f;
      background: transparent;
      border: none;
      padding: 5px 30px;
      position: absolute;
      right: 0;
      bottom: 23px;
    }
  }

  input[type="password"] {
    width: 100%;
    height: 3rem;
    padding: 0 15px;
    margin-bottom: 20px;
  }

  input[type="submit"] {
    color: #fff;
    background: #ef561f;
    border: 2px solid #ef561f;
    padding: 10px 60px;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.25rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}


/* main resource hub container, after topnav */
.resource-hub-main {
  margin-top: 10px;

  .sidebar {
    margin-top: 40px;

    .list-group {
      .fw-bold {
        position: relative;

        &::after {
          content: "";
          width: 16px;
          height: 9px;
          background: url("/images/chevron-down-lightblue.svg") no-repeat;
          display: inline-block;
          top: 50%;
          position: absolute;
          transform: translateY(-10%);
          right: 0;
        }
      }

      ul {
        display: none;
      }

      &.active {
        .fw-bold {
          &::after {
            transform: rotate(180deg);
          }
        }

        ul {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .resource-hub-main {
    .sidebar {
      .list-group {
        ul {
          display: block;
        }

        .fw-bold {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.profile-nav {
  font-size: 1.25rem;

  a {
    border: 0;
  }
}

.m-resource-navbar {
  .container-fluid {
    padding: 0;
  }

  .navbar-brand {
    font-size: 1.25rem;
    padding-left: 0;
    color: $midnight;
  }

  .navbar-nav {
    font-size: 1.25rem;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      text-align: center;
      width: 50%;
      margin-bottom: 16px;

      &:nth-child(odd) {
        border-right: 1px solid $midnight;
      }
    }

    div#sign-out-btn {
      width: 50%;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 991px) {
  .m-resource-navbar {
    .container {
      justify-content: center;
    }
  }
}

@media (min-width: $desktop) {
  .m-resource-navbar {
    .navbar-brand {
      font-size: 1.25rem;
    }

    .navbar-nav {
      font-size: 1.25rem;

      a {
        width: auto;
        margin-bottom: 0;
      }

      div#sign-out-btn {
        width: auto;
      }
    }
  }

  .profile-nav a {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid $midnight;
  }

  #resource-hub-main {
    padding-left: 100px;

    .card-body {
      .m-standard-button {
        display: inline-block;
        margin: 0;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }

  .profile-nav {
    font-size: 1.25rem;
  }

  #resource-hub {
    .list-group.fw-bold {
      font-size: 1.5rem;
    }

    .list-group.asset-type {
      font-size: 1.125rem;
    }
  }
}

@media (min-width: $large-desktop) {
  .m-resource-navbar {
    .navbar-brand {
      font-size: 1.5rem;
    }

    .navbar-nav {
      font-size: 1.5rem;
    }
  }

  .profile-nav {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: $desktop) {
  .sign-in-col-left {
    padding-left: 20px;
    padding-right: 20px;

    hr {
      margin-top: 2rem;
    }
  }

  .sign-in-col-right {
    img {
      margin-top: -100px;
    }
  }
}

/* who we serve */
.m-serve-greybg {
  padding: 1.75rem;
  margin-top: 60px;
  background: #f2f2f2;
}

@media only screen and (min-width: $desktop) {
  .m-serve-greybg {
    padding: 32px 132px;
    margin-top: 90px;
  }
}

.m-serve-gradientboxes {
  .m-serve-gradientbox {
    padding: 32px;
    margin-bottom: 32px;
    color: $white;
    text-align: left;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .08);
    background: linear-gradient(180deg, #22a8c7, #005973 200%);

    &:nth-child(2) {
      background: linear-gradient(180deg, #22a8c7 -50%, #005973 150%);
    }

    &:nth-child(3) {
      background: linear-gradient(180deg, #22a8c7 -100%, #005973 100%);
    }

    &:nth-child(4) {
      background: linear-gradient(180deg, #22a8c7 -150%, #005973 50%);
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      font-size: 1.25rem;
      line-height: 32px;
    }
  }

  .m-standard-button {
    display: inline-block;
  }
}

@media only screen and (min-width: $desktop) {
  .m-serve-gradientboxes {
    .m-serve-gradientbox {
      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
}

.m-blue-grid__tiles-assessment {

  .m-carousel-controls {
    justify-content: center;
    margin-top: 70px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $white;
    background: transparent;
    margin: 7px 22px;
    width: 20px;
    height: 20px;

    &.active {
      background: $white;
    }
  }

  .glider-bga-prev {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }

  .glider-bga-next {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }
}

.m-blue-grid__tiles-workshops {

  .m-carousel-controls {
    justify-content: center;
    margin-top: 70px;
    display: flex;
  }

  .glider-dots {
    margin: 0;
  }

  .glider-dot {
    border: 2px solid $white;
    background: transparent;
    margin: 7px 12px;
    width: 20px;
    height: 20px;

    &.active {
      background: $white;
    }
  }

  .glider-bgw-prev {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-prev.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }

  .glider-bgw-next {
    position: relative;
    color: #fff;
    background: url("/images/glider-arrow-next.svg") no-repeat;
    width: 50px;
    height: 50px;
    border: 0;
  }
}

@media only screen and (min-width: $desktop) {
  .m-blue-grid__tiles-workshops {
    .glider-dot {
      margin: 7px 22px;
    }
  }
}

/* Videos */
.m-behavior-group {
  margin-bottom: 22px;
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  .m-behavior-group__content {
    font-size: 1.25rem;
    line-height: 1.4;

    p {
      margin-bottom: 18px;
    }

    .m-behavior-group__content-image {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      h2 {
        padding-left: 18px;
        font-size: 2.5rem;
      }

      img {
        width: 78px;
      }
    }
  }
}

.m-video-single {
  .m-video-container {
    padding: 36px;
    position: relative;

    &::before {
      position: absolute;
      width: 50%;
      height: 100%;
      right: 0;
      top: 0;
      background: #f2f2f2;
      display: block;
      content: "";
    }
  }

  margin-bottom: 88px;
  margin-top: 30px;
}

.m-video-group {
  margin-top: 20px;

  .m-video-group__row {
    margin-bottom: 37px;
  }

  .m-video-group__row-video {
    padding: 36px;
    position: relative;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -50%;
      top: 0;
      background: #f2f2f2;
      display: block;
      content: "";
    }
  }

  .m-video-group__row-content {
    font-size: 1.25rem;
    line-height: 1.4;
    margin-top: 26px;
  }
}

button.vjs-big-play-button {
  transform: scale(.75);
}

@media only screen and (min-width: $desktop) {
  .m-behavior-group__image.desktop-only {
    img {
      width: 120px;
    }
  }

  .m-behavior-group {
    display: flex;
    align-items: top;
    margin-top: 80px;

    .m-behavior-group__content {
      font-size: 1.5rem;
      line-height: 1.4;
      padding-left: 32px;

      p {
        margin-bottom: 18px;
      }

      .m-behavior-group__content-image {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h2 {
          padding-left: 0;
        }

        img {
          width: 78px;
        }
      }
    }
  }

  .m-video-single {
    .m-video-container {
      align-content: space-evenly;
      padding: 0;

      &::before {
        width: 100%;
        right: -50%;
      }
    }

    margin-bottom: 88px;
    margin-top: 50px;
  }

  .m-video-group__row {
    display: flex;
    align-items: center;

    .m-video-group__row-video {
      width: 50%;
    }
  }

  .m-video-group {

    .m-video-group__row-content {
      width: 50%;
    }
  }

  button.vjs-big-play-button {
    transform: scale(1);
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-video-single {
    .m-video-container {
      padding: 30px 10px;
    }
  }
}

.m-video-faq {
  background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945);

  .accordionSection {
    max-width: 1312px;
    margin-bottom: 0;
    margin-top: 50px;
    background: $white;

    .accordion-item:first-of-type {
      padding-top: 20px;
    }

    .accordion-item:last-of-type {
      border: 0;
    }

    .accordion-item {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .m-video-faq {
    .container {
      max-width: 100%;
      padding: 0;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-video-faq {
    background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945);

    .accordionSection {

      .accordion-item:first-of-type {
        padding-top: 50px;
      }

      .accordion-item {
        margin-left: 160px;
        margin-right: 160px;
      }
    }
  }
}
/* Copy modal */
.copy-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 1;
  display: none;

  &.active {
    display: block;
  }

  .copy-modal {
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 44px 34px 0;
    width: 90%;

    #close-modal {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 1.5rem;

      svg {
        width: 17px;
        height: 17px;
      }
    }

    .copy-modal-eyebrow {
      font-size: 1.5rem;
      text-align: center;
    }

    .copy-modal-heading {
      font-size: 2rem;
      text-align: center;
      font-weight: 700;
      line-height: 36px;
    }

    input {
      width: 100%;
      padding: 10px;
    }

    .m-modal-buttons {
      text-align: center;
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      .m-inverse-button {
        order: 2;
        margin-top: 8px;
      }

      button {
        display: inline-block;
      }
    }

    #resultText {
      text-align: center;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .copy-modal-container {
    .copy-modal {
      width: auto;

      .m-modal-buttons {
        display: block;

        .m-inverse-button {
          margin-top: 0;
        }
      }
    }
  }
}

.page-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 25, 68, .7);
  z-index: 1000;
  display: none;

  &.active {
    display: block;
    z-index: 1000;
  }

  .page-modal {
    background: #131944;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 0;
    width: 90%;
    font-family: "Source Sans Pro" !important;

    #close-modal {
      position: absolute;
      margin: 0 .5rem;
      top: 0;
      right: 0;
      font-size: 1.5rem;

      svg {
        width: 17px;
        height: 17px;
        fill: #fff;
        stroke: #fff;
        stroke-width: 25;
      }
    }
  }
  h2{
    font-size: 2.125rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
  p {
    font-size: 1.75rem;
    line-height: 2.2rem;
    font-weight: 700;
    margin: 2.5rem 0;
  }
}

@media only screen and (min-width: $tablet) {
  .page-modal-container {
    .page-modal {
      width: auto;
      max-width: 500px;
    }

    .col-lg-6:first-child {
      padding: 0;
    }
  }
  .text-32 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .page-modal-container {
    background: none;
  }
  .page-modal {
    display: none;
  }
}


/* footer content */
.m-footer-demo {
  position: relative;
  overflow: hidden;

  .m-footer-demo__image-container {
    position: relative;

    img {
      width: 100%;
    }

    &::before {
      content: "";
      background: url("/images/tlc-mountain-blue.svg") no-repeat;
      background-size: cover;
      height: 37%;
      width: 77%;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
    }
  }



  .m-footer-demo__container {
    max-width: 100%;
  }
}

.m-footer-demo__content {
  padding: 50px 10px;
  text-align: center;
  width: 100%;

  .text-52 {
    font-size: 2.325rem;
  }
}

@media only screen and (max-width: 767px) {
  .m-footer-demo {
    .m-footer-demo__image-container {
      height: 400px;

      img {
        height: 100%;
        width: auto;
        position: absolute;
        right: 0;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .m-footer-demo__content {
    padding: 50px 70px;
  }
}

@media only screen and (min-width: $desktop) {
  .m-footer-demo__content {
    padding: 50px 170px;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-footer-demo {
    overflow: hidden;

    .m-footer-demo__image-container {
      height: auto;

      img {
        position: relative;
        height: auto;
        max-width: 100% !important;
      }

      &::before {
        content: none;
      }
    }

    &::before {
      content: "";
      background: url("/images/tlc-mountain-blue.svg") no-repeat;
      background-size: cover;
      height: 42%;
      width: 43%;
      right: 0;
      bottom: -15px;
      position: absolute;
      z-index: 1;
      top: auto;
    }
  }

  .m-footer-demo__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px;
    text-align: left;
    width: 50%;

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      left: -100%;
      background: $midnight;
      position: absolute;
      top: 0;
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {

  .m-footer-demo__content {
    padding: 62px 40px;

    .text-52 {
      font-size: 3.25rem;
    }
  }
}

footer {
  color: $white;
  background: $midnight;
  padding: 0 !important;

  a {
    color: $white;
    text-decoration: none;

    &:visited,
    &:hover,
    &:active {
      color: $white;
    }
  }
}

.m-footer-global {
  padding: 20px 20px 0;

  .m-footer-global__logo {
    padding: 32px 0 30px;
  }
}

.m-footer-global__links {
  padding: 20px 0 0;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline;
      list-style-type: none;
      padding: 5px 32px;
      border-right: 1px solid rgba(255, 255, 255, .4);
      line-height: 2.5;
      white-space: nowrap;

      &:first-child {
        padding-left: 0;
      }

      &:nth-child(4) {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border: 0;
      }
    }
  }
}

.m-footer-social {
  padding: 30px 0;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline;
      list-style-type: none;
      padding: 5px 14px;
      border-right: 0;
      font-size: 1.5rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 765px) {
  .m-footer-global__links {
    ul {
      li {
        &:nth-child(4) {
          padding-left: 32px;
        }
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-footer-global__links {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      li {
        padding: 5px 16px;

        &:last-child {
          padding: 5px 16px;
          border-right: 1px solid rgba(255, 255, 255, .4);
        }
      }
    }

    .m-footer-social {
      padding: 0 0 0 30px;

      ul {
        li {
          padding: 5px 10px;
          border-right: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: $x-large-desktop) {
  .m-footer-global__links {
    ul {
      li {
        padding: 5px 32px;

        &:last-child {
          padding: 5px 32px;
        }
      }
    }

    .m-footer-social {
      padding: 0 0 0 30px;

      ul {
        li {
          padding: 5px 10px;
          border-right: 0;
        }
      }
    }
  }
}

.m-footer-base {
  background: $black;
}

.m-footer-baselinks {
  padding: 20px 0;
}

.m-footer-baselinks__links {
  padding: 20px 0 0;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline;
      list-style-type: none;
      padding: 5px 24px;
      border-right: 1px solid $white;
      font-size: .875rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border: 0;
      }
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-footer-baselinks__links {
    padding: 0;
  }
}
//footer links
//h2: 24px #25cae1 line-height: 1.2
//h3: 14px #fff
//links: e6e6e6
.m-footer-navheader {
  font-size: 1.5rem;
  color: #25cae1;
  margin: 2rem 0 0;
  font-family: "Source Serif Pro", serif;
}

.m-footer-navheader__subheader {
  font-size: .875rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: 600;
  line-height: 1.25;
}

.m-footer-navheader__subheader-link {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: .5rem;
  display: block;
  line-height: 1.25;
}

.m-footer-navlinks {
  margin-top: 1rem;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 1rem;
    color: #e6e6e6;
    line-height: 1.25;
  }

  a {
    color: #e6e6e6;
  }
}

.m-footer-copyright {
  padding: 2rem calc(var(--bs-gutter-x) * .5);
  font-size: 0.875rem;
}

@media only screen and (min-width: $desktop) {
  .m-footer-content {
    .desktop-flex-only {
      display: none !important;
    }
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-footer-global {
    display: flex;
    justify-content: space-between;
    padding: 0 calc(var(--bs-gutter-x) * .5);
  }

  .m-footer-global__links {
    padding: 0;
  }

  .m-footer-social {
    padding: 0 30px;
  }

  .m-footer-copyright {
    padding: 5rem 0 2rem;
  }

  .m-footer-baselinks {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: $large-desktop) {
  .m-footer-content {
    .desktop-flex-only {
      display: flex !important;
    }
  }
}
//forms styling
.tlc-form {
  overflow: hidden;

  .col-md-12,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3 {
    padding: 0 10px;
  }

  .tlc-events-event-item {
    padding: 32px;
  }

  &.m-sales-form {
    overflow: visible;
    background: linear-gradient(#fff, #bde5ee, #0089b2, $midnight 70%);
    padding-bottom: 64px;

    .container {
      margin-top: -64px;
    }
  }

  select.form-control, textarea.form-control, input.form-control {
    width: 100%;
    height: 3rem;
    padding: 0 15px;
  }

  textarea.form-control {
    height: 8rem;
  }
  /* .ktc-radio input, .ktc-checkbox input {
    width: auto;
    height: auto;
  } */
  .right-required {
    float: right;

    p {
      padding-top: 14px;
      color: #ef561f;
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
    }
  }

  .form-heading {
    border-bottom: 1px solid #e6e6e6;
    height: 57px;
    margin-top: 14px;
    margin-bottom: 40px;

    h2 {
      float: left;
    }
  }

  h2 {
    font-size: 2rem;
  }

  .form-heading-no-top {
    border-bottom: 1px solid #e6e6e6;
    height: 0px;
    margin-bottom: 30px;
  }

  .form-field {
    padding: 0 0 20px;
  }

  .hidden-feilds {
    height: 0px;
  }

  label.control-label {
    font-weight: 600;
    font-size: 1.25rem;
  }

  input[type="submit"] {
    color: #fff !important;
    background: #ef561f;
    border: 2px solid #ef561f;
    padding: 10px 60px;
    font-weight: 700;
    text-decoration: none;
    font-size: 1.25rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      background: #e44810;
    }

    &:focus {
      background: #be3c0e;
    }

    &:active {
      background: #ef561f;
      border: 2px solid $midnight;
    }
  }

  .ktc-checkbox {
    display: flex;
  }

  .ktc-checkbox label {
    padding-left: 8px;
    position: relative;
    bottom: 5px;
    line-height: 1.3;
    display: inline;
    color: #11171d;

    a {
      font-weight: 700;
    }
  }

  .ktc-checkbox input {
    height: 23px;
    width: 33px;
    max-width: 33px;
    min-width: 33px;
  }

  .ktc-radio input {
    width: 24px;
    height: 24px;
    margin: 10px 5px 10px 0;
  }

  .field-validation-error {
    color: #ef561f;
  }

  .ktc-radio label {
    margin-right: 20px;
    bottom: 5px;
    position: relative;
  }

  select {
    background: url("/images/chevron-down-select.svg") no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 35px !important;
  }

  form {
    position: relative;
    background: $white;
    padding: 40px 20px 60px;

    .form-text-top {
      p {
        font-size: 1.125rem;
        line-height: 1.3;
      }
    }
  }

  .m-certification-formheader {
    padding: 46px 20px 0;
  }

  .editing-form-control-nested-control p i {
    line-height: 1.3;
  }
}

@media only screen and (max-width: 991px) {
  .m-certification-events {
    form {
      .form-heading {
        h2 {
          display: none;
        }
      }
    }
  }
}

.m-lpi-form__gradient {
  position: relative;
  padding-bottom: 200px;
  margin-bottom: -140px;

  &::before {
    content: "";
    background: linear-gradient(180deg, #fff, #bde5ee, #0089b2, #131945 150%);
    width: 200%;
    height: 100%;
    display: block;
    position: absolute;
    left: -50%;
    top: 0;
  }

  .container {
    position: relative;
  }

  h3 {
    margin-top: 56px;
    font-size: 1.75rem;
    font-weight: 700;
    font-family: "Source Serif Pro", serif;
    text-align: center;
  }
}

@media only screen and (min-width: $desktop) {
  .m-lpi-form__gradient {
    position: relative;
    padding-bottom: 340px;
    margin-bottom: -250px;

    h3 {
      margin-top: 56px;
      font-size: 2rem;
    }
  }

  .tlc-form {
    form {
      padding: 64px 112px;
    }

    h2 {
      font-size: 2.25rem;
    }

    input[type="submit"] {
      left: 0;
      transform: none;
    }

    .right-required {
      p {
        padding-top: 20px;
      }
    }

    .form-text-top {
      p {
        font-size: 1.5rem;
      }
    }
  }
}


.thank-you-icon img {
  height: 135px;
}

.thank-you-section {
  text-align: center;
  background-color: $white;
  position: relative;

  h2 {
    font-size: 2.6rem;
  }

  p {
    font-size: 1.5rem;
    width: 80%;
    margin: auto;
    line-height: 32px;
  }
}

.blog-listing {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 44px;
  gap: 2.75rem;
}

.blog-listing__item {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 21px;
  gap: 1.3125rem;

  &:hover {
    img {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
    }

    .blog-listing__link {
      text-decoration: underline;
    }
  }
}

.blog-listing__imgbox {
  position: relative;
  padding-bottom: 56.25%;
  min-height: 223px;
  min-height: 13.9375rem;
}

.blog-listing__imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.title-box {
  text-align: center;
  width: 100%;
  max-width: 1088px;
  max-width: 68rem;
  margin: 0 auto;
  margin-bottom: 31px;
  margin-bottom: 1.9375rem;
  position: relative;
  z-index: 2;
  font-family: "Source Serif Pro", serif;

  h1 {
    font-size: 2.735rem;
    font-weight: 700;
  }
}

.txt_facebook,
.txt_lindedin,
.txt_twitter {
  font-size: 2rem;
  color: #ef561f;
}

.recent-blogs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
  gap: 3.125rem;
  margin-bottom: 56px;
  margin-bottom: 3.5rem;
}

.recent-blogs__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  gap: 1.25rem;

  &:hover {
    .recent-blogs__title {
      text-decoration: underline !important;
    }

    img {
      box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
    }
  }
}

.recent-blogs__imgbox {
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 0 auto;
  min-height: 143px;
  min-height: 8.9375rem;
}

.recent-blogs__imgbox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.recent-blogs__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.recent-blogs__title {
  margin-bottom: 11px;
  margin-bottom: .6875rem;
  font-size: 20px;
  font-size: 1.625rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 700;
}

.recent-blogs__info {
  margin: 0;
  margin-top: 0;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem;
  color: #333;
}

.m-resource-header {
  font-weight: 700;
  font-family: "Source Serif Pro", serif;
  font-size: 2.675rem;
  margin-bottom: 20px;
  text-align: center;
}

.m-resource-header__content {
  margin-bottom: 0;
  font-size: 1.375rem;
  text-align: center;
  padding: 0 20px;
}

@media only screen and (min-width: $desktop) {
  .m-resource-header {
    font-size: 3.125rem;
  }

  .m-resource-header__content {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

.blog-listing__link {
  text-decoration: none;
}

.blog-listing__title {
  font-weight: 700;
  font-size: 1.625rem;
  margin-bottom: 12px;
}

@media only screen and (min-width: $desktop) {
  .blog-listing__title {
    font-weight: 700;
    font-size: 1.875rem;
    margin-bottom: 12px;
  }
}

.blog-listing__info {
  color: #333;
  margin-bottom: 0;
}

.ebook-details__imgbox {
  text-align: center;

  img {
    height: auto;
    position: relative;
    right: auto;
    width: 100%;
    max-width: 1440px;
  }
}

@media only screen and (min-width: $desktop) {
  .ebook-details__imgbox {
    height: 500px;
    overflow: hidden;
  }
}

.ebook-details {
  max-width: 1088px;
  margin: auto;
  background: #fff;
  margin-top: -50px;
  position: relative;
  margin-bottom: 50px;

  h1 {
    font-family: "Source Serif Pro", serif;
    color: #0081a6;
    font-weight: 700;
  }

  .col-12 {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.ebook-details__title {
  font-size: 2rem;
  text-align: center;
  background: $white;
  padding: 40px 0 0;
  margin-bottom: 0;
}

@media only screen and (min-width: $desktop) {
  .ebook-details__title {
    font-size: 2.25rem;
    margin-top: 0;
    margin-bottom: 0;
    padding: 60px 0 0;
  }
}

.gradient-lt-blue {
  background: linear-gradient(#fff, #bde5ee, #0089b2, #131945);

  div[id^="form"] {
    background-color: #fff;
  }

  &.m-ebooks-whitepapers {
    background: linear-gradient(#fff 50%, #bde5ee, #0089b2, #131945 100%);

    div[id^="form"] {
      padding: 0;
    }

    form {
      padding: 0 40px 64px !important;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .gradient-lt-blue {
    div[id^="form"] {
      padding: 50px;
      padding-top: 0;
    }
  }
}

.gated-content-page .gradient-lt-blue {
  padding-bottom: 20px !important;
  background: linear-gradient(#fff, #bde5ee, #0089b2, $midnight 95%);
}

.gated-content-page {
  padding-top: 30px;

  form {
    padding-top: 0;
  }

  div[id^="form"] {
    padding: 0 20px;
  }

  .ebook-details__title {
    padding: 40px 20px 0;
  }
}

@media only screen and (min-width: $desktop) {
  .gated-content-page {
    padding-top: 60px;
  }
}

.gated-asset-banner-title {
  font-size: 2.25rem;
  margin-bottom: 16px;
}

@media only screen and (min-width: $desktop) {
  .gated-asset-banner-title {
    font-size: 3.125rem;
    margin-bottom: 16px;
  }
}

.asset-title-area {
  padding-top: 30px;
  text-align: center;
}

@media only screen and (min-width: $desktop) {
  .asset-title-area {
    padding: 64px 50px 50px;
  }
}

.ebook-details__text h2 {
  color: #0081a6;
  font-size: 2.125rem;
}

.ebook-details__text span {
  font-size: 1.25rem !important;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.2rem;
}

@media only screen and (min-width: $desktop) {
  .ebook-details__text span {
    font-size: 1.5rem !important;
    line-height: 1.4rem;
  }
}

.gated-asset-banner-author {
  font-size: 1.25rem;
}

@media only screen and (min-width: $desktop) {
  .gated-asset-banner-author {
    font-size: 1.5rem;
  }
}

.standard-banner__assettype {
  background-color: #fcddd2;
  color: #bd3200;
  padding: 2px 8px;
  font-size: 1.25rem;
  margin-bottom: 16px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
}

.form-title .ebook-details__title {
  margin-bottom: 12px;
}

.form-title img {
  padding-top: 20px;
  width: 100%;
  max-width: 300px;
}

@media only screen and (min-width: $desktop) {
  .form-title img {
    width: auto;
    max-width: 400px;
  }
}

.thank-you-page {
  padding-top: 91px;
  padding-top: 5.6875rem;
}

.thank-you-content {
  display: flex;
  flex-direction: column;
  gap: 77px;
  gap: 2rem;
}

.thank-you-details_col1, .thank-you-details_col2 {
  flex: 1;
}

.thank-you-details_col2 {
  text-align: center;
}

.wrap {
  position: relative;
}

.hList {
  margin: 0 0 20px -10px;
  padding-left: 0;
}

.hList > li {
  display: inline-block;
  padding: 0 10px 5px;
}

.fb .txt_xxlg {
  font-size: 40px;
}

.event-date {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Source Serif Pro", serif;
  color: #0081a6;
  line-height: 1.3;
  margin-bottom: 16px;
}

.event-details {
  font-size: 1.25rem;
  line-height: 1.4;
}

.tlc-events-list-container {
  gap: 16px;
  margin-bottom: 40px;
  justify-content: center;
}

.tlc-events-event-item {
  box-shadow: 2px 2px 20px 0px #00000014;
  padding: 32px 32px 16px;
  max-height: 300px;
  width: 100%;
  background: $white;
}

@media only screen and (min-width: $tablet) {
  .tlc-events-event-item {
    width: 48%;
  }
}

@media only screen and (min-width: $desktop) {
  .tlc-events-event-item {
    width: 31%;
  }
}

.events-webinars-form {
  form {
    padding-top: 0 !important;
  }
}

.remote-only, .in-person-only {
  display: none;
}

.virtual .remote-only, .in-person .in-person-only {
  display: block;
}

.event-sponsor {
  background: #e7eff0;
  color: #007495;
  padding: 2px 8px;
}

.tlc-events-title {
  font-size: 2.375rem;
  text-align: center;
  margin: 0;
}

@media only screen and (min-width: $desktop) {
  .tlc-events-title {
    font-size: 3.125rem;
  }
}

.tlc-events-title-container {
  padding: 40px 20px;
}

.tlc-events-title-container p {
  font-size: 1.25rem;
  text-align: left;
  line-height: 1.3;

  a {
    font-weight: 700;
  }
}

@media only screen and (min-width: $desktop) {
  .tlc-events-title-container p {
    text-align: center;
    font-size: 1.5rem;
  }
}

@media (min-width: 36em) {
  .blog-listing {
    gap: 52px;
    gap: 3.25rem;
  }

  .blog-listing__item {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    gap: 1.5rem;
  }

  .blog-listing__imgbox {
    width: 50%;
  }

  .blog-listing__content {
    flex: 1;
  }

  .recent-blogs__imgbox {
    min-height: 164px;
    min-height: 10.25rem;
  }

  .recent-blogs__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 33px;
    line-height: 2.0625rem;
    margin-bottom: 14px;
    margin-bottom: .875rem;
  }
}

@media (min-width: 48em) {
  .blog-listing {
    flex-direction: row;
    gap: 24px;
    gap: 1.5rem;
  }

  .blog-listing__item {
    flex-direction: column;
    align-items: initial;
    flex: 1;
  }

  .blog-listing__imgbox {
    width: 100%;
  }

  .blog-listing__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .recent-blogs {
    margin-bottom: 80px;
    margin-bottom: 64px;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px 32px;
    gap: 4rem 2rem;
  }
}

@media (min-width: $desktop) {
  .title-box {
    margin-top: 60px;
    margin-bottom: 32px;

    h1 {
      font-size: 3.125rem;
    }
  }

  .blog-listing {
    flex-direction: row;
    gap: 24px;
    gap: 1.5rem;
  }

  .recent-blogs__imgbox {
    min-height: 273px;
    min-height: 17.0625rem;
  }

  .thank-you-content {
    padding: 1rem 0;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.125rem;
    display: flex;
  }
}

@media (min-width: 75em) {
  .blog-listing__imgbox {
    min-height: 420px;
    min-height: 26.25rem;
  }

  .recent-blogs {
    grid-template-columns: repeat(3, 1fr);
  }
}

.tlcControlLabel {
  display: none;
}

.events-item-filters {
  margin-bottom: 32px;
  text-align: center;

  select {
    font-weight: 600;
  }
}

@media only screen and (min-width: $desktop) {
  .events-item-filters {
    justify-content: center;
    display: flex;
  }
}

.tlcInputGroup {
  display: inline-flex;
  padding: 0 20px;
  margin-bottom: 17px;
  width: 100%;

  select {
    padding: 18px 46px 18px 30px;
    background: url("/images/chevron-down.svg") no-repeat;
    background-size: 16px;
    background-position: calc(100% - 20px);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    text-align: center;
    border: 1px solid $midnight;
    font-size: 1.25rem;
    max-width: 225px;
    margin: 0 auto;

    option {
      text-align: left;
      color: $midnight;
      font-weight: 400;
      font-size: 1.25rem;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .tlcInputGroup {
    margin-bottom: 0;
    width: auto;

    select {
      width: auto;
    }
  }
}

.tlcFormControl option {
  font-weight: 600;
  font-size: 1.25rem;
}

a.recent-blogs__link {
  text-decoration: none;
}

.tlc-events-list-container a {
  text-decoration: none;
}

span.r_filetype {
  text-transform: uppercase;
  color: #007495;
  background-color: #e7eff0;
  padding: 2px 8px;
  margin-left: 8px;
}

.events-style {
  color: #007495;
  background-color: #e7eff0;
  padding: 2px 8px;
  margin-left: 8px;
}

.m-leadership-unlocked .m-standard-button {
  margin: 0;
  display: inline-block;
}

.webinar-posted img {
  margin-right: 4px;
  margin-top: -4px;
}

.webinar-date {
  font-size: 2.5rem;
  line-height: 3.125rem;
}

/* Dropdown nav banner  */
img.nav-banner-logo {
  max-width: 70%;
  padding: 30px 0px 10px;
}

@media only screen and (min-width: $tablet) {
  img.nav-banner-logo {
    max-width: 100%;
    padding: 32px 0px 32px 10px;
  }
}

/* Old blog styling */
.imgFlex {
  max-width: 100%;
}
/* ----------------------------------------------------------------------
 Blocks
---------------------------------------------------------------------- */
.blocks > li {
  width: 100%;
  margin: 0 0 20px;
}

.blocks.blocks_noMarginBtm > li {
  margin-bottom: 0;
}

.blocks_padded > li {
  padding: 20px 50px;
}

/* ---------------------------------------------------------------------
Masonry
------------------------------------------------------------------------ */

.masonry,
.masonry-col {
  display: block;
  text-align: center;
}

.masonry-col {
  padding: 20px 10px;
  position: relative;
}

.masonry-col_noPad {
  padding: 0;
}

/* ---------------------------------------------------------------------
Mixin Adjustment Styles
------------------------------------------------------------------------ */
.pushLeft5 {
  margin-left: 5px;
}

.pushLeft10 {
  margin-left: 10px;
}

.pushLeft15 {
  margin-left: 15px;
}

.pushLeft20 {
  margin-left: 20px;
}

.pushLeft40 {
  margin-left: 40px;
}

.pushRight5 {
  margin-right: 5px;
}

.pushRight10 {
  margin-right: 10px;
}

.pushRight20 {
  margin-right: 20px;
}

.pushRight40 {
  margin-right: 40px;
}

.pushTop5 {
  margin-top: 5px;
}

.pushTop10 {
  margin-top: 10px;
}

.pushTop20 {
  margin-top: 20px;
}

.pushTop30 {
  margin-top: 30px;
}

.pushTop40 {
  margin-top: 40px;
}

.pushTop50 {
  margin-top: 50px;
}

.pushTop60 {
  margin-top: 60px;
}

.pushBtm0 {
  margin-bottom: 0;
}

.pushBtm5 {
  margin-bottom: 5px;
}

.pushBtm10 {
  margin-bottom: 10px;
}

.pushBtm15 {
  margin-bottom: 15px;
}

.pushBtm20 {
  margin-bottom: 20px;
}

.pushBtm30 {
  margin-bottom: 30px !important;
}

.pushBtm40 {
  margin-bottom: 40px;
}

.pushBtm50 {
  margin-bottom: 50px;
}

.pushBtm60 {
  margin-bottom: 60px;
}

.pullLeft5 {
  margin-left: -5px;
}

.pullLeft10 {
  margin-left: -10px;
}

.pullLeft20 {
  margin-left: -20px;
}

.pullLeft40 {
  margin-left: -40px;
}

.pullRight5 {
  margin-right: -5px;
}

.pullRight10 {
  margin-right: -10px;
}

.pullRight20 {
  margin-right: -20px;
}

.pullRight40 {
  margin-right: -40px;
}

.pullTop5 {
  margin-top: -5px;
}

.pullTop10 {
  margin-top: -10px;
}

.pullTop20 {
  margin-top: -20px;
}

.pullTop25 {
  margin-top: -25px;
}

.pullTop40 {
  margin-top: -40px;
}

.pullTop60 {
  margin-top: -60px;
}

.pullBtm5 {
  margin-bottom: -5px;
}

.pullBtm10 {
  margin-bottom: -10px;
}

.pullBtm20 {
  margin-bottom: -20px;
}

.pullBtm40 {
  margin-bottom: -40px;
}

.padRight5 {
  padding-right: 5px;
}

.padRight10 {
  padding-right: 10px;
}

.padRight20 {
  padding-right: 20px;
}

.padRight40 {
  padding-right: 40px;
}

.padLeft5 {
  padding-left: 5px;
}

.padLeft10 {
  padding-left: 10px;
}

.padLeft20 {
  padding-left: 20px;
}

.padLeft40 {
  padding-left: 40px;
}

.padTop5 {
  padding-top: 5px;
}

.padTop10 {
  padding-top: 10px;
}

.padTop20 {
  padding-top: 20px;
}

.padTop30 {
  padding-top: 30px;
}

.padTop40 {
  padding-top: 40px;
}

.padTop60 {
  padding-top: 60px !important;
}

.padBtm5 {
  padding-bottom: 5px;
}

.padBtm10 {
  padding-bottom: 10px;
}

.padBtm20 {
  padding-bottom: 20px;
}

.padBtm30 {
  padding-bottom: 30px;
}

.padBtm40 {
  padding-bottom: 40px;
}

.alignCenter {
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.vAlignMiddle > * {
  vertical-align: middle !important;
}

.vAlignTop > * {
  vertical-align: top;
}

.vAlignBottom > * {
  vertical-align: bottom;
}

.nopadTop {
  padding-top: 0px !important;
}

.nopadBtm {
  padding-bottom: 0px !important;
}


@media screen and (min-width: 768px) {

  /* ----------------------------------------------------------------------
  Blocks
  ---------------------------------------------------------------------- */
  .blocks {
    margin-left: -1.5625%;
    font-size: 0;
  }

  .blocks > li {
    display: inline-block;
    margin: 0 0 1.5625% 1.5625%;
    line-height: 1.4;
    vertical-align: top;
  }

  .blocks_8col > li {
    width: 10.9375%;
  }

  .blocks_7col > li {
    width: 12.72321%;
  }

  .blocks_6col > li {
    width: 15.10417%;
  }

  .blocks_5col > li {
    width: 18.4375%;
  }

  .blocks_4col > li {
    width: 23.4375%;
  }

  .blocks_3col > li {
    width: 31.77083%;
  }

  .blocks_2col > li {
    width: 48.4375%;
  }

  .blocks_paddedX > li {
    padding: 0 40px;
  }

  .blocks_vAlignMiddle > li {
    vertical-align: middle;
  }

  /* ---------------------------------------------------------------------
  Masonry
  ------------------------------------------------------------------------ */
  .masonry {
    display: table;
    width: 100%;
  }

  .masonry-col {
    display: table-cell;
    padding: 40px 20px;
    position: relative;
    vertical-align: top;
  }

  .masonry-col_tab {
    display: table-cell;
    position: relative;
    padding: 20px;
    vertical-align: middle;
    text-align: left;
  }

  .masonry-col_alignMiddle {
    vertical-align: middle;
  }

  .masonry-col_alignBottom {
    vertical-align: bottom;
  }

  .masonry-col_noPad {
    padding: 0;
  }

  .masonry-col_bg.masonry-col_noPad {
    line-height: 0;
  }

  .masonry-col_bg {
    height: 400px;
  }

  .masonry-col_1of3 {
    width: 30.333%;
  }

  .masonry-col_2of3 {
    width: 66.666%;
  }

  .masonry-col_1of4 {
    width: 25%;
  }

  .masonry-col_1of2 {
    width: 50%;
  }

  .masonry_gutter2col .masonry-col_1of3 {
    width: 32.533%;
  }

  .masonry_gutter2col .masonry-col_2of3 {
    width: 66.066%;
  }

  .masonry_gutter2col .masonry-col_gutter {
    width: 1.6%;
    padding: 0;
  }

  .masonry_gutter3col .masonry-col_1of3 {
    width: 32.26%;
  }

  .masonry_gutter3col .masonry-col_gutter {
    width: 1.6%;
    padding: 0;
  }

  .masonry_gutter4col .masonry-col_1of4 {
    width: 23.8%;
  }

  .masonry_gutter4col .masonry-col_gutter {
    width: 1.6%;
    padding: 0;
  }

  .masonry-hd {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .masonry-date {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .pushLeft40 {
    margin-left: 20px;
  }

  .pushRight40 {
    margin-right: 20px;
  }

  .pushTop40 {
    margin-top: 20px;
  }

  .pushTop60 {
    margin-top: 30px;
  }

  .pushBtm40 {
    margin-bottom: 20px;
  }

  .pushBtm60 {
    margin-bottom: 30px;
  }

  .padRight40 {
    padding-right: 20px;
  }

  .padLeft40 {
    padding-left: 20px;
  }

  .padTop40 {
    padding-top: 20px;
  }

  .padBtm40 {
    padding-bottom: 20px;
  }

  .pullTop40 {
    margin-top: -20px;
  }

  .pullBtm40 {
    margin-bottom: -40px;
  }
}
