//variables to override for bootstrap

$blue: #007970 !default;
$link-decoration: underline !default;
$link-hover-color: #007970 !default;
$link-hover-decoration: underline !default;

/* $font-family-base
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;
*/
//breakpoints xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px

$phone: 576px !default;
$tablet: 768px !default;
$desktop: 992px !default;
$large-desktop: 1200px !default;
$x-large-desktop: 1400px !default;
$xx-large-desktop: 1600px !default;
$x-large-desktop-margin: 1600px !default;
$xxl-desktop: 1800px !default;
$xl-margin-offset: 250px !default;
//fa override
$fa-font-path: "../scss/webfonts" !default;

//sizes
$standard-border-radius: 4px !default;

//colors
$white: #fff !default;
$black: #000 !default;

//$blue-gradient: linear-gradient(0deg, $blue-grad-dark, $blue-grad-light) !default;
//Font Sizes
//14px
$small-regular-text: .875rem !default;
//20px
$large-regular-text: 1.125rem !default;

//spacing
$standard-module-margin: 30px !default;
$header-logo-offset: 235px !default;

//Wiley colors
$dark-blue: #121e3f !default;
$light-blue: #3993b7 !default;
$mid-blue: #008bbe !default;
$light-grey: #f2f2f2 !default;
$mid-grey: #e6e6e6 !default;
$dark-grey: #323131 !default;
$orange: #ef561f !default;
$orangehover: #e44810 !default;
$orangeselected: #be3c0e !default;
$midnight: #041e42 !default;
$inversehover: #e6e6e6 !default;

$grid-gutter-width: 3rem !default;
